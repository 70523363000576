import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@cse/environments/environment';
import { BASEURL } from '@mcv/config';
import { AppConfig, Cse, CseUser, SingleResult } from '@mcv/core';
import { CseService } from '@mcv/coreservices';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { HeapService } from '@mcv/util-analytics';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationsService {
    model = 'App';
    environnement = environment;

    constructor(
        private httpClient: HttpClient,
        @Inject(BASEURL) protected baseUrl: string,
        private cseService: CseService,
        private authService: AuthService,
        private heapService: HeapService
    ) {
        this.loadAppConfig();
        if (this.environnement.HEAP_APP_ID?.CSE) {
            this.heapService.load(this.environnement.HEAP_APP_ID.CSE);
        }
    }

    loadAppConfig(): Observable<SingleResult<AppConfig>> {
        return this.httpClient
            .get<SingleResult<AppConfig>>(`${this.baseUrl}/cse-user/${this.model}/getConfig`)
            .pipe(
                map(r => r.data),
                tap((c) => {
                    const user = c.user as CseUser;
                    const cse = user.cse as Cse;

                    this.environnement.client_env = c.env;
                    this.authService.user = user;

                    if (this.environnement.HEAP_APP_ID?.CSE) {
                        this.heapService.identify(user.public_id);
                        this.heapService.addUserProperties({
                            'cse_user.public_id': user.public_id,
                            'cse_user.cse_public_id': user.cse_public_id,
                            'cse_user.is_admin': user.is_admin,
                            'cse.kyc_state': cse.kyc_state,
                            'cse.pricing_offer': cse.pricing_offer,
                            'cse.nb_commande': cse.nb_commande,
                            'cse.nb_employe': cse.nb_employe,
                            'cse.nb_carte': cse.nb_carte
                        });
                    }
                }),
                switchMap(c => this.cseService.view((c.user as CseUser).cse_public_id)),
                map(c => c.data),
                tap(cse => this.authService.cse = cse),
                catchError(err => {
                    this.authService.logout();
                    return of(null);
                })
            );
    }

    getEnv() {
        return this.environnement.client_env;
    }
}
