import {Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {finalize, tap} from 'rxjs/operators';
import Bugsnag from '@bugsnag/js';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const startTime = Date.now();
        let status: string;
        let response: HttpResponse<any> = null;

        // extend server response observable with logging
        return next.handle(req)
            .pipe(
                tap({
                    // Succeeds when there is a response; ignore other events
                    next: (event) => {
                        if (event instanceof HttpResponse) {
                            response = event;
                            status = 'succeeded';
                        }
                    },
                    // Operation failed; error is an HttpErrorResponse
                    error: (error) => (status = 'failed')
                }),
                // Log when response observable either completes or errors
                finalize(() => {
                    const elapsed = Date.now() - startTime;
                    const message = `${req.method} "${req.urlWithParams}" ${status} in ${elapsed} ms.`;
                    if (status === 'failed') {
                        Bugsnag.leaveBreadcrumb(message, req, 'error');
                    } else if (status === 'succeeded') {
                        Bugsnag.leaveBreadcrumb(message, req, 'request');
                    }
                    if (response !== null) {
                        const responseMessage = `${response.statusText} "${response.url}"`;
                        Bugsnag.leaveBreadcrumb(responseMessage, response, 'request');
                    }
                })
            );
    }
}

export const httpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoggingInterceptor,
        multi: true
    }
];
