import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js/max';

export function mobilePhoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        // add a whitelist for special country 'Danemark' and all countries starting with +1
        const whiteList = [
            'US',
            'CA',
            'BS',
            'BB',
            'AI',
            'AG',
            'VG',
            'VI',
            'KY',
            'BM',
            'GD',
            'TC',
            'JM',
            'MS',
            'MP',
            'MS',
            'GU',
            'AS',
            'SX',
            'LC',
            'DM',
            'VC',
            'PR',
            'DO',
            'TT',
            'KN',
            'DK'
        ];
        const value = control.value?.e164Number ?? control.value;

        if (!value || !value.startsWith('+')) {
            return null;
        }

        const phoneNumber = parsePhoneNumber(value);
        let isMobile = phoneNumber.getType() === 'MOBILE';

        if (whiteList.includes(phoneNumber.country)) {
            isMobile = true;
        }

        return isMobile ? null : { invalidMobileNumber: { value } };
    };
}
