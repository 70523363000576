import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VersionService {
    version$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor() {
    }
}
