import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import IMask from 'imask';
import { CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { AsYouType, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js/max';
import { normalizePhoneNumberFromServer } from '@mcv/core';

@Component({
    selector: 'mcv-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements AfterViewInit, ControlValueAccessor {
    country: string;
    phone: any;
    className: string;
    mask: string;
    prefix: string;
    disabled: boolean;
    dynamicmask: IMask.AnyMaskedOptions;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.France,
        CountryISO.Guadeloupe,
        CountryISO.Martinique,
        CountryISO.FrenchGuiana,
        CountryISO.WallisAndFutuna,
        CountryISO.SaintPierreAndMiquelon,
        CountryISO.SaintBarthélemy,
        CountryISO.SaintMartin,
        CountryISO.FrenchPolynesia
    ];

    currentDialCode: string;

    @ViewChild('phoneNumberInput', { read: ElementRef }) phoneNumberInput: ElementRef;

    constructor(
        public el: ElementRef,
        @Self() @Optional() public ngControl: NgControl,
        private changeDetector: ChangeDetectorRef
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    onChange: any = (val: number) => {

    };

    onTouch: any = (val: number) => {
    };

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.className = this.el.nativeElement.className;

            if (!this.phone) {
                const input = <HTMLInputElement>this.phoneNumberInput.nativeElement.querySelector('#phone');
                input.value = '+33';
            }
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: string): void {
        if (obj) {
            const normalizedPhone: PhoneNumber = parsePhoneNumber(normalizePhoneNumberFromServer(obj));
            this.country = normalizedPhone.country as string;
            this.phone = normalizedPhone.formatNational();
        }
    }

    onAccept($event: any): void {
        this.onChange($event.length > 0 ? $event : null);
    }

    setNewValue(phone) {
        if (phone) {
            this.currentDialCode = phone.dialCode.replace('+', '');

            const input = <HTMLInputElement>this.phoneNumberInput.nativeElement.querySelector('#phone');
            let initialCursorPosition = input.selectionStart;

            if (phone.countryCode === 'FR' && input.value.startsWith('0')) {
                input.value = input.value.replace('0', phone.dialCode + ' ');
                input.selectionEnd = input.selectionStart + phone.dialCode.length - 1;
                initialCursorPosition += phone.dialCode.length - 1;
            }

            input.value = new AsYouType().input(input.value);

            this.adjustCursorPosition(phone, input, initialCursorPosition);
        }

        this.phone = phone;
        this.changeDetector.detectChanges();

        // @ts-ignore
        if (this.phone) {
            this.onChange(this.phone.e164Number);
        } else {
            this.onChange(null);
        }
    }

    onCountryChange(country) {
        if (!this.phoneNumberInput) {
            return;
        }

        const { dialCode } = country;
        const input = <HTMLInputElement>this.phoneNumberInput.nativeElement.querySelector('#phone');
        const isInputBlank = !input.value.trim().length;

        switch (true) {
            case this.currentDialCode === dialCode && !isInputBlank:
                return;

            case !input.value.startsWith(`+${dialCode}`) && !isInputBlank:
                input.value = input.value.replace(`${this.currentDialCode}`, `${dialCode}`);
                break;

            default:
                input.value = `+${dialCode}`;
        }

        this.currentDialCode = dialCode;
    }

    adjustCursorPosition(phone, input: HTMLInputElement, cursorPosition: number) {
        const previousCharacter = input.value.charAt(cursorPosition - 1);
        const nextCharacter = input.value.charAt(cursorPosition + 1);

        const hasWhiteSpaceLeft = (previousCharacter === ' ');
        const hasWhiteSpaceRight = (nextCharacter === ' ');

        const isBackspace = phone.e164Number.length < this.phone?.e164Number?.length;
        const isLastEnteredDigit = !nextCharacter.length;

        if (cursorPosition < input.value.length) {
            switch (true) {
                case hasWhiteSpaceLeft && !hasWhiteSpaceRight && isBackspace:
                    input.selectionEnd = cursorPosition - 1;
                    break;

                case hasWhiteSpaceLeft && !hasWhiteSpaceRight:
                    input.selectionEnd = cursorPosition + 1;
                    break;

                case isLastEnteredDigit:
                    input.selectionEnd = cursorPosition + 1;
                    break;

                case isBackspace && (cursorPosition - 1) === phone.e164Number.length:
                    input.selectionEnd = input.value.length;
                    break;

                default:
                    input.selectionEnd = cursorPosition;
            }
        }
    }
}
