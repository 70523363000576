import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { BaseRepository, CseFacture } from '@mcv/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseFactureService extends BaseRepository<CseFacture> {
    protected model = 'cse-facture';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    download(publicId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/${this.model}/download/${publicId}`,
            {
                responseType: 'blob',
                observe: 'response'
            });
    }

    generateSepa(ids: number[]) {
        return this.httpClient
            .post(`${this.baseUrl}/${this.model}/generateSepa`,
                { ids },
                {
                    responseType: 'blob',
                    observe: 'response'
                });
    }
}
