import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-error-display',
    templateUrl: './error-display.component.html',
    styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent implements OnInit {
    @Input() error;

    constructor() {
    }

    ngOnInit(): void {
    }

}
