export const convertToFormData = (formValue: any): FormData => {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
        let value = formValue[key];
        if (Array.isArray(value)) {
            value = JSON.stringify(value);
        }
        if (value instanceof Date) {
            value = value.toISOString();
        }
        formData.append(key, value);
    }
    return formData;
};

export const convertJsontoFormData = (jsonObject: object, parentKey, carryFormData: FormData): FormData => {

    const formData = carryFormData || new FormData();
    let index = 0;

    // tslint:disable-next-line:forin
    for (const key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
            if (jsonObject[key] !== undefined) {
                let propName = parentKey || key;
                if (parentKey && isObject(jsonObject)) {
                    propName = parentKey + '[' + key + ']';
                }
                if (parentKey && isArray(jsonObject)) {
                    propName = parentKey + '[' + index + ']';
                }
                if (jsonObject[key] instanceof File) {
                    formData.append(propName, jsonObject[key]);
                } else if (jsonObject[key] instanceof FileList) {
                    for (let j = 0; j < jsonObject[key].length; j++) {
                        formData.append(propName + '[' + j + ']', jsonObject[key].item(j));
                    }
                } else if (isArray(jsonObject[key]) || isObject(jsonObject[key])) {
                    if (jsonObject[key].length === 0) {
                        formData.append(propName + '[]', '');
                    } else {
                        convertJsontoFormData(jsonObject[key], propName, formData);
                    }
                } else if (typeof jsonObject[key] === 'boolean') {
                    formData.append(propName, +jsonObject[key] ? '1' : '0');
                } else {
                    formData.append(propName, jsonObject[key]);
                }
            }
        }
        index++;
    }
    return formData;
};

function isArray(val): boolean {
    const toString = ({}).toString;
    return toString.call(val) === '[object Array]';
}

function isObject(val): boolean {
    return !isArray(val) && typeof val === 'object' && !!val;
}
