import {AfterViewInit, Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';

@Component({
    selector: 'mcv-civilite-select',
    templateUrl: './civilite-select.component.html',
    styleUrls: ['./civilite-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CiviliteSelectComponent),
            multi: true
        }
    ],
})
export class CiviliteSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @Input() value: string = null;
    @Input() disabled: boolean;
    @Input() errors: any = null;
    civilites = [
        {value: 'M', label: 'Monsieur'},
        {value: 'MME', label: 'Madame'},
        {value: 'MLLE', label: 'Mademoiselle'},
    ];
    control: FormControl;

    constructor(public injector: Injector) {
    }

    onChange: any = (val: number) => {
    }

    onTouch: any = (val: number) => {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            setTimeout(() => {
                this.control = ngControl.control as FormControl;
            })
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }
}
