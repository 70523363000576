import {Component, OnInit} from '@angular/core';
import {AuthService} from '@cse/app/auth/services/auth.service';
import {Intercom} from 'ng-intercom';

@Component({
    selector: 'mcv-root',
    template: `
        <router-outlet></router-outlet>
        <mcv-version></mcv-version>
        <val-default-errors>
            <ng-template valError="required" let-label>Ce champ est requis</ng-template>
            <ng-template valError="email" let-label>L'adresse email saisie n'est pas valide</ng-template>
            <ng-template valError="minlength" let-error="error" let-label>{{ label || 'Ce champ' }} doit comporter au
                moins {{ error.requiredLength }} caractères
            </ng-template>
            <ng-template valError="min" let-error="error" let-label>
                {{ label || 'Ce champ' }} ne peut pas être inférieur à {{ error.min }}
            </ng-template>
            <ng-template valError="max" let-error="error" let-label>
                {{ label || 'Ce champ' }} ne peut pas être supérieur à {{ error.max }}
            </ng-template>
            <ng-template valError="pattern" let-error="error" let-label>{{ label || 'Ce champ' }} ne respecte pas les
                règles énoncées
            </ng-template>
            <ng-template valError="notSame" let-error="error" let-label>Les valeurs ne correspondent pas</ng-template>
        </val-default-errors>
        <notifier-container></notifier-container>
        <div id="intercom"></div>
    `,
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private isAdmin = false;

    constructor(private intercom: Intercom,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.isAdmin = this.authService.superAdmin;

        if (this.isAdmin) {
            this.intercom.shutdown();
        } else if (window.frameElement === null) {
            this.intercom.boot({
                widget: {
                    'activator': '#intercom'
                }
            });
        }
    }
}
