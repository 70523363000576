import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'mcv-tristatetoggle',
    templateUrl: './tristatetoggle.component.html',
    styleUrls: ['./tristatetoggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TristatetoggleComponent),
            multi: true
        }
    ],
})
export class TristatetoggleComponent implements OnDestroy, ControlValueAccessor {
    @Input() triState = true;
    @Input() onlabel = '<i class="fas fa-check"></i>';
    @Input() offlabel = '<i class="fas fa-times"></i>';
    @Input() unknownlabel = '&middot;';
    @Input() size: 'small' | 'medium' | 'large' = 'medium';
    @Input() width = 150;
    @Input() unknownColor = '#FEAC3C';
    @Input() onColor = '#86CB37';
    @Input() offColor = '#EE2D35';
    @Output() stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    state: 'checked' | 'unchecked' | 'unknown' = 'checked';
    value: boolean;
    disabled: boolean;

    constructor(private cdr: ChangeDetectorRef) {

    }

    onChange: any = (val: number) => {
    }

    onTouch: any = (val: number) => {
    }

    ngOnDestroy() {

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj === null) {
            this.state = 'unknown';
        } else if (obj) {
            this.state = 'checked';
        } else {
            this.state = 'unchecked';
        }
    }

    setState(state: 'checked' | 'unchecked' | 'unknown') {
        this.state = state;
        let emitValue;
        switch (state) {
            case 'checked':
                emitValue = true;
                break;
            case 'unchecked':
                emitValue = false;
                break;
            default:
                emitValue = null;
                break;
        }
        this.onChange(emitValue);
        this.stateChange.emit(emitValue);
    }

    getLabel(state: 'checked' | 'unchecked' | 'unknown') {
        switch (state) {
            case 'checked':
                return this.onlabel;
            case 'unchecked':
                return this.offlabel;
            default:
                return this.unknownlabel;
        }
    }

    getColor(state: "checked" | "unchecked" | "unknown") {
        switch (state) {
            case 'checked':
                return this.onColor;
            case 'unchecked':
                return this.offColor;
            default:
                return this.unknownColor;
        }
    }
}
