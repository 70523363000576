import { Injectable } from '@angular/core';
import { Heap } from '../interfaces/Heap';

declare let heap: any;

@Injectable({
    providedIn: 'root'
})

export class HeapService implements Heap {

    appId: string;
    identity: string | null;
    userId: string;

    constructor() {
    }

    load(appId) {
        heap.load(appId);
    }

    track(eventName, eventProps) {
        heap.track(eventName, eventProps);
    }

    identify(identity) {
        heap.identify(identity);
    }

    addUserProperties(properties) {
        heap.addUserProperties(properties);
    }

    addEventProperties(properties: Object): void {
    }

    clearEventProperties(): void {
    }

    removeEventProperty(property: string): void {
    }

    resetIdentity(): void {
    }
}
