<ng-select (blur)="onTouch()"
           (change)="onSelectChange($event)"
           [addTag]="false"
           [disabled]="disabled"
           [items]="countries"
           [ngModel]="value"
           appendTo="body"
           bindLabel="cname"
           bindValue="ccode"
           class="size-large status-basic">
</ng-select>
