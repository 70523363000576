<div [formGroup]="formGroup">
    <div *ngIf="displayName" class="form-group">
        <label>{{nameLabel}}<small>*</small></label>
        <input class="form-control" formControlName="name" trim="blur" type="text"/>
    </div>
    <div class="form-group">
        <label>{{addressLabel}}<small>*</small></label>
        <div *ngIf="useGeoSearching; else defaultAddress">
            <input #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" [options]="options" class="form-control" data-cy="address1"
                   formControlName="address1"
                   maxlength="50" ngx-google-places-autocomplete trim="blur"
                   type="text"/>
        </div>
        <ng-template #defaultAddress>
            <input class="form-control"
                   data-cy="address1"
                   formControlName="address1"
                   maxlength="50"
                   trim="blur"
                   type="text">
        </ng-template>
        <val-errors controlName="address1"></val-errors>
        <small *ngIf="info">{{info}}</small>
    </div>
    <div class="form-group">
        <label for="address2">Complément d'adresse</label>
        <input class="form-control"
               data-cy="address2"
               formControlName="address2"
               id="address2"
               maxlength="50"
               name="address2"
               type="text">
    </div>
    <div class="form-row">
        <div class="form-group col-md-2">
            <label for="postcode">Code Postal<small>*</small></label>
            <input class="form-control"
                   data-cy="postcode"
                   formControlName="postcode"
                   id="postcode"
                   name="postcode"
                   trim="blur"
                   type="text">
            <val-errors controlName="postcode"></val-errors>
        </div>
        <div class="form-group col-md-6">
            <label for="city">Ville<small>*</small></label>
            <input class="form-control"
                   data-cy="city"
                   formControlName="city"
                   id="city"
                   maxlength="64"
                   name="city"
                   trim="blur"
                   type="text">
            <val-errors controlName="city"></val-errors>
        </div>
        <div class="form-group col-md-4">
            <label>Pays<small>*</small></label>
            <mcv-country-select data-cy="country" formControlName="country"></mcv-country-select>
            <val-errors controlName="country"></val-errors>
        </div>
    </div>
</div>
