export enum CseEmployeCardStateUserEnum {
    UNLOCK = 'UNLOCK',
    LOCK = 'LOCK',
    LOST = 'LOST',
    STOLEN = 'STOLEN',
    BROKEN = 'BROKEN',
    LOST_CONFIRMED = 'LOST_CONFIRMED',
    STOLEN_CONFIRMED = 'STOLEN_CONFIRMED',
    BROKEN_CONFIRMED = 'BROKEN_CONFIRMED',
    LOST_RENEW = 'LOST_RENEW',
    STOLEN_RENEW = 'STOLEN_RENEW',
    BROKEN_RENEW = 'BROKEN_RENEW',
    DESTROYED = 'DESTROYED',
}

export const CseEmployeCardStateUser: Array<{ value: string, label: string }> = [
    { value: CseEmployeCardStateUserEnum.UNLOCK, label: 'Débloquée' },
    { value: CseEmployeCardStateUserEnum.LOCK, label: 'Bloquée' },
    { value: CseEmployeCardStateUserEnum.LOST, label: 'Déclarée perdue' },
    { value: CseEmployeCardStateUserEnum.STOLEN, label: 'Déclarée volée' },
    { value: CseEmployeCardStateUserEnum.BROKEN, label: 'Déclarée cassée' },
    { value: CseEmployeCardStateUserEnum.LOST_CONFIRMED, label: 'Confirmée perdue' },
    { value: CseEmployeCardStateUserEnum.STOLEN_CONFIRMED, label: 'Confirmée volée' },
    { value: CseEmployeCardStateUserEnum.BROKEN_CONFIRMED, label: 'Confirmée cassée' },
    { value: CseEmployeCardStateUserEnum.LOST_RENEW, label: 'Perdue, renouvellement fait' },
    { value: CseEmployeCardStateUserEnum.STOLEN_RENEW, label: 'Volée, renouvellement fait' },
    { value: CseEmployeCardStateUserEnum.BROKEN_RENEW, label: 'Cassée, renouvellement fait' },
    { value: CseEmployeCardStateUserEnum.DESTROYED, label: 'Détruite' }
];

export function getCseEmployeCardStateUser(state_user: string): { value: string, label: string } {
    return CseEmployeCardStateUser.find(s => s.value === state_user);
}
