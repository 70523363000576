<div class="form-group">
    <label for="legalName">
        {{textLabel}}
    </label>
    <div class="form-row">
        <div class="p-2"><i class="fas fa-search"></i></div>
        <div class="col-11">
            <ng-select (focusout)="focusout()"
                       [(ngModel)]="selectedBusiness"
                       [addTag]="false"
                       [cdkTrapFocusAutoCapture]="focus"
                       [cdkTrapFocus]="focus"
                       [hideSelected]="true"
                       [items]="business$ | async"
                       [loading]="loading"
                       [minTermLength]="2"
                       [multiple]="false"
                       [placeholder]="placeholder"
                       [typeahead]="businessInput$"
                       bindLabel="tradename"
                       data-cy="legalName"
                       id="legalName"
                       loadingText="Recherche en cours..."
                       notFoundText="Aucune entreprise trouvée avec ce nom"
                       typeToSearchText="Saisissez le nom de votre entreprise ou son numéro de SIRET pour lancer une recherche">
                <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
                    {{item.tradename}}, <small>{{item.address1}}, {{item.postcode}} {{item.city}}</small>
                </ng-template>
            </ng-select>
        </div>
    </div>
</div>

<div class="form-group">
    Si vous ne trouvez pas l'entreprise par son nom, vous pouvez aussi saisir le <b>numéro de SIRET</b>
</div>

<ng-container *ngIf="selectedBusiness">
    <div class="form-group" id="cse-preview">
        <label for="cse-info">Vous avez selectionné :</label>
        <div class="m-0 mr-2 ml-2 p-4 border border-primary rounded" id="cse-info">
            <b>{{selectedBusiness.tradename}}</b>
            SIRET: {{(selectedBusiness.legalRegistrationNumber.substr(0, 9)) | imask:mask }}
            <br/><small>{{selectedBusiness.address1}}, {{selectedBusiness.postcode}} {{selectedBusiness.city}}</small>
        </div>
    </div>
    <button (click)="validate()"
            class="btn btn-primary btn-block"
            data-cy="nextStep">
        {{buttonLabel}}
    </button>
</ng-container>
