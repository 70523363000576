import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
    @Input() loadingIndicator: boolean;
    @Input() displayBlock = true;

    constructor() {
    }

    ngOnInit(): void {
    }

}
