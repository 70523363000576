<input (blur)="onTouch()"
       (ngModelChange)="changeModel($event)"
       *ngIf="nativeDisplay; else mcvDatepicker"
       [(ngModel)]="model"
       [disabled]="disabled"
       [imask]="dynamicmask"
       [unmask]="true"
       class="form-control"
       placeholder="{{placeholder}}"
       type="text"/>
<ng-template #mcvDatepicker>
    <input (blur)="onTouch()"
           (ngModelChange)="changeModel($event)"
           [(ngModel)]="model"
           [bsConfig]="bsConfig"
           [daysDisabled]="daysDisabled"
           [disabled]="disabled"
           [maxDate]="maxDate"
           [minDate]="minDate"
           bsDatepicker
           class="form-control"
           container="body"
           placeholder="{{placeholder}}"/>
</ng-template>
