<mcv-browser-compatibility-warning></mcv-browser-compatibility-warning>
<div class="row align-items-center" style="height: 100px">
    <div class="col-md-6">&nbsp;</div>
    <div class="col-md-6 text-center">
        <img class="col-lg-6 img-fluid mx-auto d-block" src="/assets/img/logo.png">
    </div>
</div>
<div class="row h-75">
    <div class="col-md-6 bg-light d-none d-lg-block align-self-center">
        <div class="text-center p-4">
            <img alt="Ma carte vacances" class="img-fluid shadowed"
                 src="{{baseUrl}}/img/Carte-Vacances-V1.png" style="width: 400px;">
            <h1 class="p-4">Une gestion facilitée</h1>
            <p class="text-muted">Vous pouvez commander et recharger les cartes en quelques clics
                directement depuis votre espace dédié et disponible 24h/24, 7j/7.</p>
        </div>
    </div>
    <div class="col-md align-self-center">
        <div class="card border-light m-md-5 p-md-4">
            <div class="card-body">
                <form (ngSubmit)="login()" [formGroup]="loginForm">
                    <h3 class="card-title">Se connecter</h3>
                    <mcv-error-display [error]="error"></mcv-error-display>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input autocapitalization="off"
                               autocorrect="off"
                               class="form-control"
                               data-cy="email"
                               formControlName="email"
                               id="email"
                               name="email"
                               placeholder="Email"
                               type="text">
                    </div>
                    <div class="form-group">
                        <label>Mot de passe</label>
                        <input class="form-control"
                               data-cy="password_hash"
                               formControlName="password_hash"
                               name="password_hash"
                               placeholder="Mot de passe"
                               type="password">
                    </div>
                    <div *ngIf="improveSecurity"
                         class="d-flex justify-content-center">
                        <re-captcha formControlName="recaptchaReactive"></re-captcha>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <a (click)="openLostPasswordDialog($event, lostPasswordModal)"
                               [class]="prefilled ? 'btn btn-secondary btn-block mt-2' : 'btn btn-outline-primary btn-block mt-2'"
                               href="#">
                                mot de passe oublié ?
                            </a>
                        </div>
                        <div class="col-md-6 col-12">
                            <button [disabled]="!loginForm.valid"
                                    [promiseBtn]="isLogin"
                                    class="btn btn-primary btn-block mt-2"
                                    type="submit">
                                Accéder à la plateforme
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #lostPasswordModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title align-self-center" id="motDePasseOublie">Mot de passe oublié ?</h4>
            <button (click)="modalRef.hide()" aria-label="Close" class="close" type="button">
                <i aria-hidden="true" class="far fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="sendLostPassword()" [formGroup]="lostPasswordForm">
                <div class="form-group">
                    <p><small>Entrez l'adresse e-mail indiquée au moment de la création de votre compte. Vous
                        recevrez un lien afin de réinitialiser votre mot de passe.</small></p>
                </div>
                <div class="form-group">
                    <label for="motdepass-email">Email</label>
                    <input [mcvInputAutoFocus]="true"
                           class="form-control"
                           formControlName="email"
                           id="motdepass-email"
                           name="email"
                           placeholder="nom.prenom@societe.com"
                           type="text">
                </div>
                <div class="form-group mt-5">
                    <div class="float-right">
                        <button (click)="modalRef.hide()" class="btn btn-link" type="button">Annuler</button>
                        <button [disabled]="!lostPasswordForm.valid || saving"
                                [promiseBtn]="saving"
                                class="btn btn-primary"
                                type="submit">
                            Soumettre
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
