<div class="">
    <div class="passwordusage--wrapper__font">
        <h5>Votre nouveau mot de passe doit :</h5>
        <ul class="fa-ul">
            <li [class.text-success]="same">
                <span class="fa-li">
                    <i *ngIf="same else defaultIcon" class="far fa-check-square"></i>
                </span>
                être identique avec la confirmation
            </li>
            <li [class.text-success]="maxLength">
                <span class="fa-li">
                <i *ngIf="maxLength else defaultIcon" class="far fa-check-square"></i>
                </span>être d'une longueur minimum de 8 caractères
            </li>
            <li [class.text-success]="number">
                <span class="fa-li">
                <i *ngIf="number else defaultIcon" class="far fa-check-square"></i>
                </span>inclure au moins un chiffre
            </li>
            <li [class.text-success]="uppercase">
                <span class="fa-li">
                    <i *ngIf="uppercase else defaultIcon" class="far fa-check-square"></i>
                </span>inclure au moins une lettre en majuscule
            </li>
            <li [class.text-success]="lowercase">
                <span class="fa-li">
                    <i *ngIf="lowercase else defaultIcon" class="far fa-check-square"></i>
                </span>inclure au moins une lettre en minuscule
            </li>
            <li [class.text-success]="special">
                <span class="fa-li">
                    <i *ngIf="special else defaultIcon" class="far fa-check-square"></i>
                </span>inclure au moins un caractère spécial
            </li>
            <ng-template #defaultIcon>
                <i class="far fa-square"></i>
            </ng-template>
        </ul>
    </div>
</div>
