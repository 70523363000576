<ngx-intl-tel-input #phoneNumberInput
                    (countryChange)="onCountryChange($event)"
                    (ngModelChange)="setNewValue($event)"
                    [enableAutoCountrySelect]="true"
                    [ngModel]="phone"
                    [numberFormat]="PhoneNumberFormat.International"
                    [phoneValidation]="true"
                    [preferredCountries]="preferredCountries"
                    customPlaceholder="+33 6 23 45 67 89"
                    mcvMobilePhone>
</ngx-intl-tel-input>
