import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '@mcv/config';
import {Balance, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BalancesService {
    private model = 'balance';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) protected baseUrl: string) {
    }

    view(walletId?: number): Observable<SingleResult<Balance>> {
        return this.httpClient.get<SingleResult<Balance>>(`${this.baseUrl}/${this.model}/view/${walletId || ''}`);
    }

    getBalances(walletIds: string[]): Observable<SingleResult<Balance[]>> {
        return this.httpClient.post<SingleResult<Balance[]>>(`${this.baseUrl}/${this.model}/getBalances`, {walletIds});
    }
}
