<form (ngSubmit)="onConfirm()" *ngIf="active">
    <div class="modal-header">
        <h5 class="modal-title" id="pinModalLabel">{{title}}</h5>
        <button (click)="onCancel()" aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center font-weight-light">
            {{body}}
        </p>
    </div>
    <div class="form-group" style="padding-right: 3rem">
        <div class="text-right">
            <button (click)="onCancel()" class="btn btn-link" type="button">Annuler</button>
            <button class="btn btn-primary" type="submit">Confirmer</button>
        </div>
    </div>
</form>
