import {Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Subject, Subscription} from 'rxjs';
import {MetaPaging} from '../interfaces/BaseRepository';
import {DatasourceWrapper} from '../libs/DatasourceWrapper';

@Directive()
export abstract class TableComponent<T> implements OnInit, OnDestroy {
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @Input() datasourceWrapper: DatasourceWrapper<T>;
    @Input() filter: string;
    @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();

    subscription: Subscription = new Subscription();
    paging$: Subject<MetaPaging>;
    limit = 10;
    page = 0;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    protected constructor() {
    }

    ngOnInit(): void {
        this.paging$ = this.datasourceWrapper.paging$;
        this.subscription.add(this.sort.sortChange.subscribe(sort => this.sortChange.emit(sort)));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    changePage($event: PageEvent) {
        this.pageChange.emit($event);
    }

}
