export const normalizePhoneNumberFromServer = (phone: string): string => {
    if (!phone || phone.trim().length === 0) {
        return null;
    }

    if (phone[0] !== '+') {
        phone = '+' + phone.trim();
    }

    return phone.trim();
}

export const normalizePhoneNumberForServer = (phone: string): string => {
    if (!phone || phone.trim().length === 0) {
        return null;
    }
    return phone.replace('+', '');
}
