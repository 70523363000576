import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'mcv-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true
        }
    ]
})
export class ToggleComponent implements OnInit, ControlValueAccessor {
    @Input() value: boolean;
    @Output() onValueChange = new EventEmitter<boolean>();
    disabled: boolean;

    constructor() {
    }

    onChange: any = (val: number) => {

    };

    onTouch: any = (val: number) => {
    };

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    toggleValue() {
        this.value = !this.value;
        this.onChange(this.value);
        this.onTouch(this.value);
        this.onValueChange.emit(this.value);
    }
}
