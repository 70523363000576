import {Component, OnInit} from '@angular/core';
import {Platform} from '@angular/cdk/platform';

@Component({
    selector: 'mcv-browser-compatibility-warning',
    templateUrl: './browser-compatibility-warning.component.html',
    styleUrls: ['./browser-compatibility-warning.component.scss']
})
export class BrowserCompatibilityWarningComponent implements OnInit {

    mustDisplay = false;

    constructor(private platform: Platform) {
        this.mustDisplay = platform.TRIDENT;
    }

    ngOnInit(): void {
    }

}
