<div [ngStyle]="{backgroundColor:getColor(state)}"
     class="toggle-container-{{size}} {{state}}"
     style="width:{{width}}px">
    <div (click)="setState('unchecked')"
         class="toggle-position">
        <span [innerHTML]="offlabel | safeHtml:'html'" class="label unchecked"></span>
    </div>
    <div (click)="setState('unknown')"
         *ngIf="triState"
         class="toggle-position">
        <span [innerHTML]="unknownlabel | safeHtml:'html'" class="label unknown"></span>
    </div>
    <div (click)="setState('checked')"
         class="toggle-position">
        <span [innerHTML]="onlabel | safeHtml:'html'" class="label checked"></span>
    </div>
    <div [ngStyle]="{color:getColor(state)}"
         class="dot {{state}}">
        <span [innerHTML]="getLabel(state) | safeHtml:'html'"></span>
    </div>
</div>
