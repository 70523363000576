import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

@Component({
    selector: 'mcv-user-profile-address',
    templateUrl: './user-profile-address.component.html',
    styleUrls: ['./user-profile-address.component.scss']
})
export class UserProfileAddressComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() info: string;
    @Input() displayEmail = false;
    @Input() displayName = false;
    @Input() nameLabel = 'Nom de facturation';
    @Input() addressLabel = 'Adresse';
    @Input() useGeoSearching = false;
    @Input() options: Options = {
        fields: ['address_components']
    } as Options;

    @ViewChild('placesRef') placesRef: GooglePlaceDirective;

    constructor() {
    }

    ngOnInit(): void {
    }

    nosort(_): number {
        return 0;
    }

    handleAddressChange(address: any): void {
        const streetNumber = this.findElement(address, 'street_number')?.long_name ?? '';
        const route = this.findElement(address, 'route')?.long_name ?? '';

        this.formGroup.patchValue({
            address1: streetNumber + ' ' + route ,
            address2: '',
            postcode: this.buildPostCode(address),
            city: this.findElement(address, 'locality')?.long_name ,
            country: this.findElement(address, 'country')?.short_name
        });
    }

    findElement(address: any, key: string): any {
        return address.address_components.find(element => element.types[0] === key);
    }

    buildPostCode(address: any): string {
        const postCode = this.findElement(address, 'postal_code')?.long_name ?? '';
        const prefix = this.findElement(address, 'postal_code_suffix')?.long_name ?? '';
        if (prefix) {
            return `${postCode}-${prefix}`;
        }
        return postCode;
    }
}
