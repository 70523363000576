import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Pipe, PipeTransform} from '@angular/core';
import Bugsnag from '@bugsnag/js';

@Pipe({
    name: 'formatError'
})
export class FormatErrorPipe implements PipeTransform {

    transform(e: any, ...args: unknown[]): string {
        if (typeof e === 'string') {
            Bugsnag.leaveBreadcrumb(e, null, 'error');
            Bugsnag.notify(e);
            return e;
        }
        if (e instanceof HttpErrorResponse && (e.status === 0 || e.status === 500)) {
            const message = 'Erreur Interne veuillez contacter l\'équipe de support';
            Bugsnag.notify(message);
            return message;
        } else if (e instanceof HttpResponse && e.body) {
            const error = e.body.errors[0].reason;
            Bugsnag.leaveBreadcrumb(error, null, 'error');
            Bugsnag.notify(e.body);
            return error;
        } else if (e.error) {
            const message = e.error.data ? e.error.data.message : e.message;
            Bugsnag.leaveBreadcrumb(message, null, 'error');
            Bugsnag.notify(e);
            return message;
        } else if (e.message) {
            const message = e.message;
            Bugsnag.leaveBreadcrumb(message, null, 'error');
            Bugsnag.notify(e);
            return message;
        } else {
            const message = 'Erreur Interne veuillez contacter l\'équipe de support';
            Bugsnag.leaveBreadcrumb(message, null, 'error');
            Bugsnag.notify(e);
            return message;
        }
    }
}
