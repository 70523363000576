<div class="m-5 text-center">
    <a routerLink="/auth/login">
        <img alt="Logo macartevacances.fr" src="{{baseUrl}}/img/logo.png" width="330">
    </a>
</div>
<div class="container">
    <div class="card border-light rounded-1 mt-4">
        <div class="card-body d-flex flex-column">
            <h2 class="card-title">
                Votre lien de réinitialisation à expiré.
            </h2>
            <p>Vous pouvez en générer un nouveau ci-dessous :</p>

            <button (click)="sendResetLink()" class="btn btn-outline-primary align-self-center" type="button">Envoyer un
                nouveau lien
            </button>
        </div>
    </div>
</div>
