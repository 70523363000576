import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'debug'
})
export class DebugPipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): void {
        console.log(value)
    }
}
