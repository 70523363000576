import {Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
    selector: 'mcv-search-enhanced',
    templateUrl: './search-enhanced.component.html',
    styleUrls: ['./search-enhanced.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchEnhancedComponent),
            multi: true
        }
    ],
})
export class SearchEnhancedComponent implements OnInit, ControlValueAccessor {
    @Input() suggestions$: Observable<any>;
    @Input() isFocus = false;
    @Input() placeholder = 'Rechercher...';
    @Input() customItemTemplate: TemplateRef<any>;
    @Output() select: EventEmitter<any> = new EventEmitter<any>();
    loading: boolean;
    disabled: boolean;
    value: any;

    constructor() {
    }

    onChange: any = (val: number) => {
    }

    onTouch: any = (val: number) => {
    }

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

}
