import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {VersionService} from '@cse/app/auth/services/version.service';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {

    constructor(private versionService: VersionService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                filter(r => r instanceof HttpResponse),
                tap((r: HttpResponse<any>) => this.versionService.version$.next(r.headers.get('X-Cse-Version')))
            );
    }
}
