import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, Cse, CseKycState, Result, SingleResult, TrzBusinesses} from '@mcv/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CseService extends BaseRepository<Cse> {
    model = 'cse';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    kycState(id: string): Observable<SingleResult<CseKycState>> {
        return this.httpClient.get<SingleResult<CseKycState>>(`${this.baseUrl}/${this.model}/kycState/${id}`);
    }

    recherche(query: string): Observable<Result<TrzBusinesses>> {
        return this.httpClient
            .get<Result<TrzBusinesses>>(`${this.baseUrl}/${this.model}/recherche`, {params: {query}})
            .pipe(
                catchError(_ => of({success: false, data: []}))
            );
    }

    applyTrzBusiness(cse_public_id: string, legalRegistrationNumber: string): Observable<SingleResult<Cse>> {
        return this.httpClient.put<SingleResult<Cse>>(`${this.baseUrl}/${this.model}/applyTrzBusiness/${cse_public_id}`, {legalRegistrationNumber});
    }

    massPreboarding(): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/massPreboarding`);
    }

    getStateCgu(): Observable<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/info-contract`);
    }

    acceptCgu(): Observable<any> {
        return this.httpClient.post<any>(`${this.baseUrl}/${this.model}/accept-contract`, null);
    }
}
