import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { BaseRepository, CseCommandeLine, SingleResult } from '@mcv/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseCommandeLineService extends BaseRepository<CseCommandeLine> {
    model = 'cse-commande-line';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    update(cseCommandePublicId, formData): Observable<any> {
        return this.httpClient.post<any>(`${this.baseUrl}/${this.model}/update/${cseCommandePublicId}`, formData);
    }

    getLinesByCommandeId(commandeId: string): Observable<SingleResult<string>> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/getAllForCommande?commandeId=${commandeId}`);
    }

    isContributionPresent(commandeId: string): Observable<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/checkContributionPresent?commandeId=${commandeId}`);
    }

}
