export interface CoreLog {
    _id: string;
    object: string;
    object_id: number;
    cse_id: number;
    object_data: any;
    file: string;
    line: number;
    date: { date: string, timezone_type: number, timezone: string };
    level: string;
    message: string;
    pid: number;
    hostname: string;
    sapi: string;
    context: any;
}

export interface AppLog {
    _id: string;
    message: string;
    scope: string;
    level: string;
    owner: string;
    owner_type: string;
    ip: string;
    useragent: string;
    ts: string;
    source: string;
    type: AppLogType;
    payload: any
}

export type AppLogType = 'App.Account.Preboarding_email' |
    'App.Account.Topup' |
    'App.Account.Login' |
    'App.Account.Edit' |
    'App.Card' |
    'App.Account.Remove' |
    'App.Account.Unremove'
    ;

export function getVisual(type: AppLogType): { icon: string, color: string } {
    switch (type) {
        case 'App.Account.Remove':
        case 'App.Account.Unremove':
        case 'App.Account.Login':
        case 'App.Account.Edit':
            return {
                icon: 'far fa-users',
                color: 'orange-icon'
            };
        case 'App.Card':
            return {
                icon: 'far fa-credit-card',
                color: 'blue-icon'
            };
        case 'App.Account.Preboarding_email':
            return {
                icon: 'far fa-envelope',
                color: 'purple-icon'
            };
        case 'App.Account.Topup':
            return {
                icon: 'far fa-euro-sign',
                color: 'purple-icon'
            };
    }
}
