import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
    @Input() diameter = 100;

    constructor() {
    }

    ngOnInit(): void {
    }

}
