import {Component, Inject, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BASEURL} from '@mcv/config';
import {Intercom} from 'ng-intercom';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {McvnotifierService} from '@mcv/ui';
import {map} from 'rxjs/operators';
import {ConfigurationsService} from '../../services/configurations.service';
import {AuthService} from '../../services/auth.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'mcv-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup = this.fb.group({
        email: [null, [Validators.required]],
        password_hash: [null, [Validators.required]],
        recaptchaReactive: [],
        remember_me: []
    });
    modalRef: BsModalRef;
    lostPasswordForm: FormGroup = this.fb.group({
        email: [null, [Validators.required]]
    });
    saving = false;
    subscription: Subscription = new Subscription();
    improveSecurity = false;
    error: string;
    isLogin = false;
    prefilled = false;
    redirectTo: string;

    model = 'Cse';

    constructor(@Inject(BASEURL) public baseUrl: string,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private modalService: BsModalService,
                private configurationService: ConfigurationsService,
                private intercom: Intercom,
                private notifierService: McvnotifierService,
                private authService: AuthService,
                private http: HttpClient) {
        this.redirectTo = this.route.snapshot.queryParams['redirectTo'] || '/cse';
    }

    async ngOnInit(): Promise<void> {
        if (await this.authService) {
            this.authService.cse?.state === 'prefilled' ? this.prefilled = true : this.prefilled = false;
            // console.warn('toto',this.authService.cse);
        }
        if (await this.authService.checkIp()
            .pipe(map(r => r.data.mustCaptcha))
            .toPromise()) {
            return this.reinforceSecurity();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getCseByState(): any {
        // return this.http.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/getCseByState`,'prefilled');
    }

    login(): void {
        this.isLogin = true;
        this.authService
            .login(this.loginForm.value)
            .toPromise()
            .then(_ => {
                return this.configurationService
                    .loadAppConfig()
                    .toPromise();
            })
            .then(_ => {
                this.isLogin = false;
                this.router.navigateByUrl(this.redirectTo);
            })
            .catch(err => {
                this.isLogin = false;
                this.error = err;
                // this.notifierService.error(err);
                this.reinforceSecurity();
            });
    }

    reinforceSecurity() {
        this.improveSecurity = true;
        this.loginForm.get('recaptchaReactive')
            .setValidators([Validators.required]);
    }

    openLostPasswordDialog($event: MouseEvent, lostPasswordModal: TemplateRef<any>): void {
        if (this.prefilled === false) {
            $event.preventDefault();
            this.modalRef = this.modalService.show(lostPasswordModal, {class: 'modal-dialog-centered modal-lg'});
        }
    }

    async sendLostPassword(): Promise<any> {
        try {
            if (this.lostPasswordForm.get('email'))
                this.saving = true;
            await this.authService.resetPassword(this.lostPasswordForm.value)
                .toPromise();
            this.notifierService.success('Un email vient de vous être envoyé');
            this.modalRef.hide();
        } catch (e) {
            this.error = e;
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }
}
