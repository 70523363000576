import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export const CheckPasswordSame = (group: FormGroup): any => {
    const pass = group.get('password').value;
    const confirmPass = group.get('password2').value;

    return pass === confirmPass ? null : {notSame: true};
};

export const RegexPassword = '(?=(.*[0-9]))(?=.*[\\!\\/@#$€%^&*()\\\\[\\]{}\\-_+=~`|:;"\'<>,.\\?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}';
export const CreatePasswordForm = (fb: FormBuilder): FormGroup => {
    return fb.group({
        public_id: [],
        email: [],
        password: [null, [
            Validators.minLength(8),
            Validators.pattern(RegexPassword)
        ]],
        password2: [null, [
            Validators.minLength(8),
            Validators.pattern(RegexPassword)
        ]],
    }, {validators: CheckPasswordSame});
};
