import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, CseCommande, CseCommandePricingInfos, CseCommandeStats, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseCommandeService extends BaseRepository<CseCommande> {
    model = 'cse-commande';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    stats(cse_id: string): Observable<SingleResult<CseCommandeStats>> {
        return this.httpClient.get<SingleResult<CseCommandeStats>>(`${this.baseUrl}/${this.model}/stats/${cse_id}`);
    }

    getNewOrderName(): Observable<SingleResult<string>> {
        return this.httpClient.get<SingleResult<string>>(`${this.baseUrl}/${this.model}/getNewOrderName`);
    }

    getPricingInfos(cseCommandeId: string): Observable<SingleResult<CseCommandePricingInfos>> {
        return this.httpClient.get<SingleResult<CseCommandePricingInfos>>(`${this.baseUrl}/${this.model}/getPricingInfos/${cseCommandeId}`);
    }

    export(formData: any): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/${this.model}/export`, formData, {
            responseType: 'blob',
            observe: 'response'
        });
    }
}
