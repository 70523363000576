import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'mcv-success-timer',
    templateUrl: './success-timer.component.html',
    styleUrls: ['./success-timer.component.scss']
})
export class SuccessTimerComponent implements OnInit {
    @Output() finish: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() url: string;
    @Input() message: string;
    @Input() duration = 5000;
    value = 0;
    startDate = Date.now();
    width = 0;
    step = 0;

    constructor() {
    }

    ngOnInit(): void {
        this.step = Math.ceil(this.duration / 106);
        this.initTimer();
    }

    initTimer() {
        const interval = setInterval(() => {
            if (Date.now() - this.startDate >= this.duration) {
                clearInterval(interval);
                this.finish.emit(true);
            } else {
                this.width++;
            }
        }, this.step);
    }
}
