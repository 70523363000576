import {Inject, Injectable} from '@angular/core';
import {BaseRepository, CseUser, Result, SingleResult} from '@mcv/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '@mcv/config';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseUserService extends BaseRepository<CseUser> {
    model = 'cse-user/cse-user';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    password(id: string, formValue: any): Observable<Result<CseUser>> {
        return this.httpClient.put<Result<CseUser>>(`${this.baseUrl}/${this.model}/password/${id}`, formValue);
    }

    sendActivationLink(id: string): Observable<Result<CseUser>> {
        return this.httpClient.get<Result<CseUser>>(`${this.baseUrl}/${this.model}/sendActivationLink/${id}`);
    }

    preboarding(formValue: any): Observable<SingleResult<CseUser>> {
        return this.httpClient.post<SingleResult<CseUser>>(`${this.baseUrl}/cse-user/activation/preboarding`, formValue);
    }

    onboarding(formValue: any): Observable<SingleResult<CseUser>> {
        return this.httpClient.post<SingleResult<CseUser>>(`${this.baseUrl}/cse-user/activation/onboarding`, formValue);
    }

    getPrefilledLink(id: number): Observable<SingleResult<CseUser>> {
        return this.httpClient.get<SingleResult<CseUser>>(`${this.baseUrl}/cse-user/activation/getPrefilledLink/${id}`);
    }

    getPasswordInitLink(id: number): Observable<SingleResult<CseUser>> {
        return this.httpClient.get<SingleResult<CseUser>>(`${this.baseUrl}/cse-user/activation/getPasswordInitLink/${id}`);
    }
}
