import { CseEmploye } from 'libs/core/src/interfaces/CseEmploye';
import { WalletType } from 'libs/core/src/interfaces/WalletType';
import { Contribution } from './Contribution';

export interface CseCommande {
    id?: number;
    public_id: string;
    name: string;
    idx: number;
    send: string;
    use_default_address: boolean;
    address1: string;
    address2: string;
    postcode: string;
    city: string;
    country: string;
    nb_employe_with_card: number;
    nb_employe_without_card: number;
    total_budget: number;
    is_first_commande: boolean;
    pricing_recharge: number;
    pricing_abonnement: number;
    pricing_commission: number;
    pricing_send_office: number;
    pricing_send_home: number;
    pricing_dossier: number;
    pricing_ouverture_compte: number;
    date_create: string;
    date_update: string;
    date_validated: string;
    state: string;
    state_paying: string;
    date_payed: string;
    virement_order_id: number;
    paused: boolean;
    cse_id: number;
    nb_lines: number;
    cgu_public_id: string;
    wallet_type_public_id: string;
    wallet_type: WalletType;
    wallet_type_id: number;
}

export interface CseCommandeLine {
    public_id: string;
    budget: number;
    contribution?: any;
    state: string;
    trz_transferStatus: string;
    type: string;
    cse_employe?: CseEmploye

    // used only in frontend to highlight change
    _hasBudgetChanged: boolean
}

export interface CseCommandeLineHistory extends CseCommandeLine {
    cse_commande: CseCommande;
    ContributionRelation?: Contribution;
}

export const CSE_COMMANDE_SEND_HOME = 'home';
export const CSE_COMMANDE_SEND_OFFICE = 'office';
