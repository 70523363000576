import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mobilePhoneValidator } from '../../../ui/src/lib/validators/mobile-phone.validator';

const affiliateUserFields = {
    aid: [null],
    firstname: [null, [Validators.required]],
    lastname: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    mobile: [null, [Validators.required, mobilePhoneValidator()]]
};

const affiliateLegalFields = {
    legalRegistrationNumber: [null, [Validators.required]]
};

export const CreateStep1Form = (fb: FormBuilder): FormGroup => {
    return fb.group(affiliateUserFields);
};

export const CreateStep2Form = (fb: FormBuilder): FormGroup => {
    return fb.group({
        ...affiliateUserFields,
        ...affiliateLegalFields,
        affiliate_public_id: [null],
        public_id: [null],
        legalName: [null]
    });
};
