import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
