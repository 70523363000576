import {Component, OnDestroy, OnInit} from '@angular/core';
import {VersionService} from '@cse/app/auth/services/version.service';
import * as version from '@cse/version.json';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'mcv-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit, OnDestroy {
    clientVersion = version;
    mustReload = false;
    subscription: Subscription = new Subscription();

    constructor(private versionService: VersionService) {
    }

    ngOnInit(): void {
        this.initVersionListener();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    initVersionListener() {
        this.subscription.add(this.versionService
            .version$
            .pipe(
                filter(v => !!v)
            )
            .subscribe(v => {
                const [vMajor, rMinor, rPatch] = v.split('.');
                // @ts-ignore
                const [bMajor, cMinor, cPatch] = this.clientVersion.default.version.split('.');
                if (rMinor !== cMinor) {
                    this.mustReload = true;
                } else {
                    this.mustReload = rPatch > cPatch;
                }
            })
        );
    }

    reload($event: MouseEvent) {
        $event.preventDefault();
        document.location.reload();
    }
}
