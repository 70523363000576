import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '@cse/environments/environment';
import {McvnotifierService} from '@mcv/ui';
import {Intercom} from 'ng-intercom';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'mcv-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(private authService: AuthService,
                private intercom: Intercom,
                private notifierService: McvnotifierService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.intercom.shutdown();
        this.intercom.boot({
            app_id: environment.INTERCOM
        });
        this.authService.logout();
        this.notifierService.success('Vous avez bien été déconnecté');
        this.router.navigate(['/auth/login']);
    }
}
