import {Pipe, PipeTransform} from '@angular/core';
import {BalancesService} from '@mcv/coreservices';
import {map} from 'rxjs/operators';

@Pipe({
    name: 'balance'
})
export class BalancePipe implements PipeTransform {

    constructor(private balanceService: BalancesService) {
    }

    async transform(walletId: number, ...args: string[]): Promise<string> {
        if (!walletId) {
            return new Promise(resolve => null);
        }

        try {
            return this.balanceService
                .view(walletId)
                .pipe(map(r => r.data))
                .pipe(map(r => r.authorizedBalance || "0"))
                .toPromise()
        } catch (e) {
            return new Promise(resolve => null);
        }
    }

}
