import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from '@cse/environments/environment';
import version from './version.json';
import {AppModule} from './app/app.module';
import Bugsnag from '@bugsnag/js';
import {ServiceLocator} from '@mcv/coreservices';
import {AuthService} from '@cse/app/auth/services/auth.service';

Bugsnag.start({
    apiKey: '4ec5c7051b02fd3eea8219e92306f29b',
    appVersion: version.version,
    releaseStage: environment.client_env,
    onError: function (event) {
        const authService = ServiceLocator.injector.get(AuthService);
        event.setUser(authService.user?.public_id, null, authService.user?.fullname);
    }
});


if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
