import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { IMaskModule } from 'angular-imask';
import { NotifierModule } from 'angular-notifier';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import {
    UserProfileAddressComponent
} from 'libs/ui/src/lib/components/user-profile-address/user-profile-address.component';
import { NgxNavbarModule } from 'ngx-bootstrap-navbar';
import { AlertModule } from 'ngx-bootstrap/alert';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { frLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ValdemortModule } from 'ngx-valdemort';
import { BusinessSearchComponent } from './components/business-search/business-search.component';
import { CiviliteSelectComponent } from './components/civilite-select/civilite-select.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PasswordUsageRulesComponent } from './components/password-usage-rules/password-usage-rules.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { SearchEnhancedComponent } from './components/search-enhanced/search-enhanced.component';
import { YesnoSelectComponent } from './components/yesno-select/yesno-select.component';
import { BalancePipe } from './pipes/balance.pipe';
import { CountryPipe } from './pipes/country.pipe';
import { InputAutoFocusDirective } from './directives/input-auto-focus.directive';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { ErrorDisplayComponent } from './components/error-display/error-display.component';
import { FormatErrorPipe } from './pipes/format-error.pipe';
import { BadgeComponent } from './components/badge/badge.component';
import { TristatetoggleComponent } from './components/tristatetoggle/tristatetoggle.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SvgComponent } from 'libs/ui/src/lib/components/svg-component/svg.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { SuccessTimerComponent } from './components/success-timer/success-timer.component';
import { A11yModule } from '@angular/cdk/a11y';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MobilePhoneDirective } from './directives/mobile-phone.directive';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { FormlyModule } from '@ngx-formly/core';

defineLocale('fr', frLocale);

const PIPES = [
    CountryPipe,
    BalancePipe,
    FormatErrorPipe,
    SafeHtmlPipe
];

const COMPONENTS = [
    AddressFormComponent,
    BadgeComponent,
    BusinessSearchComponent,
    CiviliteSelectComponent,
    ConfirmationModalComponent,
    CountrySelectComponent,
    DatepickerComponent,
    ErrorDisplayComponent,
    LoadingComponent,
    PasswordInputComponent,
    PasswordUsageRulesComponent,
    PdfViewerComponent,
    PhoneInputComponent,
    ProgressBarComponent,
    SearchEnhancedComponent,
    SuccessTimerComponent,
    SvgComponent,
    ToggleComponent,
    TristatetoggleComponent,
    UserProfileAddressComponent,
    YesnoSelectComponent
];

const DIRECTIVES = [
    InputAutoFocusDirective,
    ScrollSpyDirective,
    MobilePhoneDirective
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxNavbarModule,
        IMaskModule,
        Angular2PromiseButtonModule,
        MatProgressSpinnerModule,
        ValdemortModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgSelectModule,
        AlertModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        UiSwitchModule.forRoot({
            size: 'small',
            switchColor: 'white',
            defaultBgColor: 'grey',
            defaultBoColor: 'grey',
            checkedLabel: '',
            uncheckedLabel: ''
        }),
        Angular2PromiseButtonModule.forRoot({
            spinnerTpl: '<i class="ml-1 fas fa-spinner fa-spin"></i>',
            btnLoadingClass: 'is-loading'
        }),
        NotifierModule.withConfig({
            behaviour: {
                showDismissButton: true
            },
            position: {
                horizontal: {
                    position: 'right'
                },
                vertical: {
                    position: 'top'
                }
            }
        }),
        MatInputModule,
        ReactiveFormsModule,
        TypeaheadModule,
        MatProgressBarModule,
        NgxTrimDirectiveModule,
        NgxExtendedPdfViewerModule,
        A11yModule,
        NgxIntlTelInputModule,
        GooglePlaceModule,
        FormlyModule
    ],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
        AddressFormComponent
    ],
    exports: [
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
        NotifierModule,
        IMaskModule,
        MatProgressSpinnerModule,
        ModalModule,
        NgxNavbarModule,
        ValdemortModule,
        NgSelectModule,
        UiSwitchModule,
        AlertModule,
        BsDropdownModule,
        TooltipModule,
        BsDatepickerModule,
        Angular2PromiseButtonModule
    ],
    providers: [
        CurrencyPipe,
        FormatErrorPipe
    ]
})
export class UiModule {
}
