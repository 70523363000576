import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BASEURL} from '@mcv/config';
import {AuthService} from '@cse/app/auth/services/auth.service';
import {McvnotifierService} from '@mcv/ui';

@Component({
    selector: 'mcv-reset-link-expired',
    templateUrl: './reset-link-expired.component.html',
    styleUrls: ['./reset-link-expired.component.scss']
})
export class ResetLinkExpiredComponent implements OnInit {
    private userId: string;
    private userKey: string;

    constructor(
        @Inject(BASEURL) public baseUrl: string,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private notifierService: McvnotifierService
    ) {
        const {id, key} = this.activatedRoute.snapshot.queryParams;
        this.userId = id;
        this.userKey = key;
    }

    ngOnInit(): void {
    }

    async sendResetLink(): Promise<void> {
        try {
            await this.authService.resetPassword({public_id: this.userId}).toPromise();
            this.notifierService.success('Un email vient de vous être envoyé');
        } catch (e) {
            this.notifierService.error(e);
        }
    }
}
