import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { mobilePhoneValidator } from '../validators/mobile-phone.validator';


@Directive({
    selector: '[mcvMobilePhone]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: MobilePhoneDirective,
        multi: true
    }]
})
export class MobilePhoneDirective implements Validator {

    validate(control: AbstractControl): ValidationErrors | null {
        return mobilePhoneValidator()(control);
    }
}
