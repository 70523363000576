export const Country: { ccode: string, cname: string }[] = [
    { ccode: 'AF', cname: 'Afghanistan' },
    { ccode: 'ZA', cname: 'Afrique du Sud' },
    { ccode: 'AL', cname: 'Albanie' },
    { ccode: 'DZ', cname: 'Algérie' },
    { ccode: 'DE', cname: 'Allemagne' },
    { ccode: 'AD', cname: 'Andorre' },
    { ccode: 'AO', cname: 'Angola' },
    { ccode: 'AI', cname: 'Anguilla' },
    { ccode: 'AQ', cname: 'Antarctique' },
    { ccode: 'AG', cname: 'Antigua-et-Barbuda' },
    { ccode: 'SA', cname: 'Arabie Saoudite' },
    { ccode: 'AR', cname: 'Argentine' },
    { ccode: 'AM', cname: 'Arménie' },
    { ccode: 'AW', cname: 'Aruba' },
    { ccode: 'AU', cname: 'Australie' },
    { ccode: 'AT', cname: 'Autriche' },
    { ccode: 'AZ', cname: 'Azerbaïdjan' },
    { ccode: 'BS', cname: 'Bahamas' },
    { ccode: 'BH', cname: 'Bahreïn' },
    { ccode: 'BD', cname: 'Bangladesh' },
    { ccode: 'BB', cname: 'Barbade' },
    { ccode: 'BE', cname: 'Belgique' },
    { ccode: 'BZ', cname: 'Belize' },
    { ccode: 'BJ', cname: 'Bénin' },
    { ccode: 'BM', cname: 'Bermudes' },
    { ccode: 'BT', cname: 'Bhoutan' },
    { ccode: 'BY', cname: 'Biélorussie' },
    { ccode: 'BO', cname: 'Bolivie' },
    { ccode: 'BA', cname: 'Bosnie-Herzégovine' },
    { ccode: 'BW', cname: 'Botswana' },
    { ccode: 'BR', cname: 'Brésil' },
    { ccode: 'BN', cname: 'Brunei Darussalam' },
    { ccode: 'BG', cname: 'Bulgarie' },
    { ccode: 'BF', cname: 'Burkina Faso' },
    { ccode: 'BI', cname: 'Burundi' },
    { ccode: 'KH', cname: 'Cambodge' },
    { ccode: 'CM', cname: 'Cameroun' },
    { ccode: 'CA', cname: 'Canada' },
    { ccode: 'CV', cname: 'Cap-Vert' },
    { ccode: 'CL', cname: 'Chili' },
    { ccode: 'CN', cname: 'Chine' },
    { ccode: 'CY', cname: 'Chypre' },
    { ccode: 'CO', cname: 'Colombie' },
    { ccode: 'KM', cname: 'Comores' },
    { ccode: 'KP', cname: 'Corée du Nord' },
    { ccode: 'KR', cname: 'Corée du Sud' },
    { ccode: 'CR', cname: 'Costa Rica' },
    { ccode: 'CI', cname: 'Côte d’Ivoire' },
    { ccode: 'HR', cname: 'Croatie' },
    { ccode: 'CU', cname: 'Cuba' },
    { ccode: 'DK', cname: 'Danemark' },
    { ccode: 'DJ', cname: 'Djibouti' },
    { ccode: 'DM', cname: 'Dominique' },
    { ccode: 'EG', cname: 'Égypte' },
    { ccode: 'AE', cname: 'Émirats Arabes Unis' },
    { ccode: 'EC', cname: 'Équateur' },
    { ccode: 'ER', cname: 'Érythrée' },
    { ccode: 'ES', cname: 'Espagne' },
    { ccode: 'EE', cname: 'Estonie' },
    { ccode: 'SZ', cname: 'Eswatini' },
    { ccode: 'US', cname: 'États-Unis' },
    { ccode: 'ET', cname: 'Éthiopie' },
    { ccode: 'FJ', cname: 'Fidji' },
    { ccode: 'FI', cname: 'Finlande' },
    { ccode: 'FR', cname: 'France' },
    { ccode: 'GA', cname: 'Gabon' },
    { ccode: 'GM', cname: 'Gambie' },
    { ccode: 'GE', cname: 'Géorgie' },
    { ccode: 'GS', cname: 'Géorgie du Sud et les îles Sandwich du Sud' },
    { ccode: 'GH', cname: 'Ghana' },
    { ccode: 'GI', cname: 'Gibraltar' },
    { ccode: 'GR', cname: 'Grèce' },
    { ccode: 'GD', cname: 'Grenade' },
    { ccode: 'GL', cname: 'Groenland' },
    { ccode: 'GP', cname: 'Guadeloupe' },
    { ccode: 'GU', cname: 'Guam' },
    { ccode: 'GT', cname: 'Guatemala' },
    { ccode: 'GG', cname: 'Guernesey' },
    { ccode: 'GN', cname: 'Guinée' },
    { ccode: 'GQ', cname: 'Guinée équatoriale' },
    { ccode: 'GW', cname: 'Guinée-Bissau' },
    { ccode: 'GY', cname: 'Guyane' },
    { ccode: 'GF', cname: 'Guyane française' },
    { ccode: 'HT', cname: 'Haïti' },
    { ccode: 'HN', cname: 'Honduras' },
    { ccode: 'HK', cname: 'Hong Kong' },
    { ccode: 'HU', cname: 'Hongrie' },
    { ccode: 'BV', cname: 'Île Bouvet' },
    { ccode: 'CX', cname: 'Île Christmas' },
    { ccode: 'IM', cname: 'Ile de Man' },
    { ccode: 'NF', cname: 'Île Norfolk' },
    { ccode: 'AX', cname: 'Îles Åland' },
    { ccode: 'KY', cname: 'Iles Cayman' },
    { ccode: 'CC', cname: 'Îles Cocos' },
    { ccode: 'CK', cname: 'Îles Cook' },
    { ccode: 'FK', cname: 'Îles Falkland' },
    { ccode: 'FO', cname: 'Îles Féroé' },
    { ccode: 'HM', cname: 'Îles Heard-et-MacDonald' },
    { ccode: 'MP', cname: 'Îles Mariannes du Nord' },
    { ccode: 'MH', cname: 'Îles Marshall' },
    { ccode: 'UM', cname: 'Îles mineures éloignées des États-Unis' },
    { ccode: 'SB', cname: 'Îles Salomon' },
    { ccode: 'TC', cname: 'Îles Turques-et-Caïques' },
    { ccode: 'VI', cname: 'Îles Vierges américaines' },
    { ccode: 'VG', cname: 'Îles Vierges britanniques' },
    { ccode: 'IN', cname: 'Inde' },
    { ccode: 'ID', cname: 'Indonésie' },
    { ccode: 'IQ', cname: 'Irak' },
    { ccode: 'IR', cname: 'Iran' },
    { ccode: 'IE', cname: 'Irlande' },
    { ccode: 'IS', cname: 'Islande' },
    { ccode: 'IL', cname: 'Israël' },
    { ccode: 'IT', cname: 'Italie' },
    { ccode: 'JM', cname: 'Jamaïque' },
    { ccode: 'JP', cname: 'Japon' },
    { ccode: 'JE', cname: 'Jersey' },
    { ccode: 'JO', cname: 'Jordanie' },
    { ccode: 'KZ', cname: 'Kazakhstan' },
    { ccode: 'KE', cname: 'Kenya' },
    { ccode: 'KG', cname: 'Kirghizistan' },
    { ccode: 'KI', cname: 'Kiribati' },
    { ccode: 'KW', cname: 'Koweït' },
    { ccode: 'LA', cname: 'Laos' },
    { ccode: 'LS', cname: 'Lesotho' },
    { ccode: 'LV', cname: 'Lettonie' },
    { ccode: 'LB', cname: 'Liban' },
    { ccode: 'LR', cname: 'Libéria' },
    { ccode: 'LY', cname: 'Libye' },
    { ccode: 'LI', cname: 'Liechtenstein' },
    { ccode: 'LT', cname: 'Lituanie' },
    { ccode: 'LU', cname: 'Luxembourg' },
    { ccode: 'MO', cname: 'Macao' },
    { ccode: 'MK', cname: 'Macédoine' },
    { ccode: 'MG', cname: 'Madagascar' },
    { ccode: 'MY', cname: 'Malaisie' },
    { ccode: 'MW', cname: 'Malawi' },
    { ccode: 'MV', cname: 'Maldives' },
    { ccode: 'ML', cname: 'Mali' },
    { ccode: 'MT', cname: 'Malte' },
    { ccode: 'MA', cname: 'Maroc' },
    { ccode: 'MQ', cname: 'Martinique' },
    { ccode: 'MU', cname: 'Maurice' },
    { ccode: 'MR', cname: 'Mauritanie' },
    { ccode: 'YT', cname: 'Mayotte' },
    { ccode: 'MX', cname: 'Mexique' },
    { ccode: 'FM', cname: 'Micronésie' },
    { ccode: 'MD', cname: 'Moldavie' },
    { ccode: 'MC', cname: 'Monaco' },
    { ccode: 'MN', cname: 'Mongolie' },
    { ccode: 'ME', cname: 'Monténégro' },
    { ccode: 'MS', cname: 'Montserrat' },
    { ccode: 'MZ', cname: 'Mozambique' },
    { ccode: 'MM', cname: 'Myanmar' },
    { ccode: 'NA', cname: 'Namibie' },
    { ccode: 'NR', cname: 'Nauru' },
    { ccode: 'NP', cname: 'Népal' },
    { ccode: 'NI', cname: 'Nicaragua' },
    { ccode: 'NE', cname: 'Niger' },
    { ccode: 'NG', cname: 'Nigeria' },
    { ccode: 'NU', cname: 'Niue' },
    { ccode: 'NO', cname: 'Norvège' },
    { ccode: 'NC', cname: 'Nouvelle-Calédonie' },
    { ccode: 'NZ', cname: 'Nouvelle-Zélande' },
    { ccode: 'OM', cname: 'Oman' },
    { ccode: 'UG', cname: 'Ouganda' },
    { ccode: 'UZ', cname: 'Ouzbékistan' },
    { ccode: 'PK', cname: 'Pakistan' },
    { ccode: 'PW', cname: 'Palau' },
    { ccode: 'PS', cname: 'Palestine' },
    { ccode: 'PA', cname: 'Panama' },
    { ccode: 'PG', cname: 'Papouasie-Nouvelle-Guinée' },
    { ccode: 'PY', cname: 'Paraguay' },
    { ccode: 'NL', cname: 'Pays-Bas' },
    { ccode: 'PE', cname: 'Pérou' },
    { ccode: 'PH', cname: 'Philippines' },
    { ccode: 'PN', cname: 'Pitcairn' },
    { ccode: 'PL', cname: 'Pologne' },
    { ccode: 'PF', cname: 'Polynésie française' },
    { ccode: 'PT', cname: 'Portugal' },
    { ccode: 'PR', cname: 'Puerto Rico' },
    { ccode: 'QA', cname: 'Qatar' },
    { ccode: 'CF', cname: 'République centrafricaine' },
    { ccode: 'CD', cname: 'République démocratique du Congo' },
    { ccode: 'DO', cname: 'République dominicaine' },
    { ccode: 'CG', cname: 'République du Congo' },
    { ccode: 'CZ', cname: 'République tchèque' },
    { ccode: 'RE', cname: 'Réunion' },
    { ccode: 'RO', cname: 'Roumanie' },
    { ccode: 'GB', cname: 'Royaume-Uni' },
    { ccode: 'RU', cname: 'Russie' },
    { ccode: 'RW', cname: 'Rwanda' },
    { ccode: 'EH', cname: 'Sahara occidental' },
    { ccode: 'BL', cname: 'Saint-Barthélemy' },
    { ccode: 'KN', cname: 'Saint-Kitts-et-Nevis' },
    { ccode: 'SM', cname: 'Saint-Marin' },
    { ccode: 'MF', cname: 'Saint-Martin (partie française)' },
    { ccode: 'SX', cname: 'Saint-Martin (partie néerlandaise)' },
    { ccode: 'PM', cname: 'Saint-Pierre-et-Miquelon' },
    { ccode: 'VA', cname: 'Saint-Siège (Vatican)' },
    { ccode: 'VC', cname: 'Saint-Vincent-et-les Grenadines' },
    { ccode: 'SH', cname: 'Sainte-Hélène' },
    { ccode: 'LC', cname: 'Sainte-Lucie' },
    { ccode: 'SV', cname: 'Salvador' },
    { ccode: 'WS', cname: 'Samoa' },
    { ccode: 'AS', cname: 'Samoa américaines' },
    { ccode: 'ST', cname: 'Sao Tomé-et-Principe' },
    { ccode: 'SN', cname: 'Sénégal' },
    { ccode: 'RS', cname: 'Serbie' },
    { ccode: 'SC', cname: 'Seychelles' },
    { ccode: 'SL', cname: 'Sierra Leone' },
    { ccode: 'SG', cname: 'Singapour' },
    { ccode: 'SK', cname: 'Slovaquie' },
    { ccode: 'SI', cname: 'Slovénie' },
    { ccode: 'SO', cname: 'Somalie' },
    { ccode: 'SD', cname: 'Soudan' },
    { ccode: 'LK', cname: 'Sri Lanka' },
    { ccode: 'SS', cname: 'Sud-Soudan' },
    { ccode: 'SE', cname: 'Suède' },
    { ccode: 'CH', cname: 'Suisse' },
    { ccode: 'SR', cname: 'Suriname' },
    { ccode: 'SJ', cname: 'Svalbard et Jan Mayen' },
    { ccode: 'SY', cname: 'Syrie' },
    { ccode: 'TJ', cname: 'Tadjikistan' },
    { ccode: 'TW', cname: 'Taiwan' },
    { ccode: 'TZ', cname: 'Tanzanie' },
    { ccode: 'TD', cname: 'Tchad' },
    { ccode: 'TF', cname: 'Terres australes et antarctiques françaises' },
    { ccode: 'IO', cname: 'Territoire britannique de l’Océan Indien' },
    { ccode: 'TH', cname: 'Thaïlande' },
    { ccode: 'TL', cname: 'Timor-Leste' },
    { ccode: 'TG', cname: 'Togo' },
    { ccode: 'TK', cname: 'Tokelau' },
    { ccode: 'TO', cname: 'Tonga' },
    { ccode: 'TT', cname: 'Trinité-et-Tobago' },
    { ccode: 'TN', cname: 'Tunisie' },
    { ccode: 'TM', cname: 'Turkménistan' },
    { ccode: 'TR', cname: 'Turquie' },
    { ccode: 'TV', cname: 'Tuvalu' },
    { ccode: 'UA', cname: 'Ukraine' },
    { ccode: 'UY', cname: 'Uruguay' },
    { ccode: 'VU', cname: 'Vanuatu' },
    { ccode: 'VE', cname: 'Venezuela' },
    { ccode: 'VN', cname: 'Viêt Nam' },
    { ccode: 'WF', cname: 'Wallis-et-Futuna' },
    { ccode: 'YE', cname: 'Yémen' },
    { ccode: 'ZM', cname: 'Zambie' },
    { ccode: 'ZW', cname:'Zimbabwe' }
];
