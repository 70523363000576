import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BASEURL } from '@mcv/config';
import { CreatePasswordForm } from '@mcv/core';
import { AuthService } from '../../services/auth.service';
import { McvnotifierService } from '@mcv/ui';

@Component({
    selector: 'mcv-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    passForm: FormGroup;
    userId: string;
    userKey: string;
    saving = false;
    update = true;

    constructor(private authService: AuthService,
                private fb: FormBuilder,
                @Inject(BASEURL) public baseUrl: string,
                private router: Router,
                private notifierService: McvnotifierService,
                private activatedRoute: ActivatedRoute) {
        const { id, key } = this.activatedRoute.snapshot.queryParams;
        this.userId = id;
        this.userKey = key;
        this.passForm = CreatePasswordForm(this.fb);
        this.passForm.addControl('key', this.fb.control({}));
        this.passForm.patchValue({ public_id: id, key });
    }

    async ngOnInit(): Promise<void> {
        try {
            const result = await this.authService.checkPasswordCode(this.passForm.value).toPromise();

            if (result.success === false) {
                this.notifierService.error('Votre lien de réinitialisation de mot de passe à expiré.');
                this.passForm.disable();

                await this.router.navigate(['auth', 'reset-link-expired'], {
                    queryParams: {
                        id: this.userId,
                        key: this.userKey
                    }
                });
            }
        } catch (e) {
            await this.handleError(e);
        }
    }

    async resetPassword(): Promise<void> {
        try {
            this.saving = true;
            await this.authService.confirmPasswordReset(this.passForm.value)
                .toPromise();
            this.notifierService.success('Mot de passe modifié avec succès, vous pouvez utiliser votre nouveau mot de passe pour vous connecter');
            this.router.navigate(['/auth/login']);
        } catch (e) {
            await this.handleError(e);
        } finally {
            this.saving = false;
        }
    }

    private async handleError(e) {
        this.passForm.disable();

        switch (e.status) {
            case 400:
                await this.router.navigate(['auth', 'reset-link-invalid']);
                break;
            case 403:
                await this.router.navigate(['auth', 'reset-link-expired'], {
                    queryParams: {
                        id: this.userId,
                        key: this.userKey
                    }
                });
                break;
            default:
                this.notifierService.error(e);
        }
    }
}
