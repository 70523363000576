import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { BaseRepository, CseImport, CseImportResult, SingleResult } from '@mcv/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseImportService extends BaseRepository<CseImport> {
    model = 'cse-import';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    import(formData: any, progressBar$: Subject<number>): Promise<SingleResult<CseImportResult>> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<SingleResult<CseImportResult>>(`${this.baseUrl}/${this.model}/import`, formData, {
                    reportProgress: true,
                    observe: 'events'
                })
                .subscribe((event: HttpEvent<any>) => {
                    switch (event.type) {
                        case HttpEventType.Sent:
                            break;
                        case HttpEventType.ResponseHeader:
                            break;
                        case HttpEventType.UploadProgress:
                            progressBar$.next(Math.round(event.loaded / event.total * 100));
                            break;
                        case HttpEventType.Response:
                            resolve(event.body);
                    }
                }, err => reject(err)
                );
        });
    }

    confirm(formData: any): Observable<any> {
        return this.httpClient.put<any>(`${this.baseUrl}/${this.model}/confirm`, formData);
    }

    download(publicId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/${this.model}/download/${publicId}`,
            {
                responseType: 'blob',
                observe: 'response'
            });
    }
}
