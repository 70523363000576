import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, Cgu, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CguService extends BaseRepository<Cgu> {
    model = 'Cgu';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getLast(): Observable<SingleResult<Cgu>> {
        return this.httpClient.get<SingleResult<Cgu>>(`${this.baseUrl}/${this.model}/getLast`);
    }

    getLastContract(): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/getLastContract`);
    }

    getLastCguPdc(): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/getLastCguPdc`);
    }
}
