import { Inject, Injectable } from '@angular/core';
import { BaseRepository, CseCommandeLineError, SingleResult } from '@mcv/core';
import { HttpClient } from '@angular/common/http';
import { BASEURL } from '@mcv/config';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseCommandeLineErrorService extends BaseRepository<CseCommandeLineError> {
    model = 'cse-commande-line-error';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getErrorsForCommande(cseCommandeId: string): Observable<SingleResult<CseCommandeLineError[]>> {
        return this.httpClient.get<SingleResult<CseCommandeLineError[]>>(`${this.baseUrl}/${this.model}/getErrorsForCommande?cse_commande_public_id=${cseCommandeId}`);
    }
}
