import { CseEmploye } from './CseEmploye';

export interface CseEmployeCard {
    id: number,
    public_id: string,
    pin: string,
    date_create?: string;
    dateConvert: string,
    dateCreate: string,
    dateExpire: string,
    dateSent?: string;
    estimatedDeliveryDate?: string;
    dateDelivered?: string;
    dateActivation: string,
    isCardExpired: boolean;
    daysLeftUntilExpiry: number;
    state: string,
    state_user: string,
    purchase_source: string,
    trz_virtualCardId: number,
    trz_virtualIsLive: boolean,
    trz_virtualStatusCode: string,
    trz_virtualCardPublicToken: number,
    trz_cardId: number,
    trz_cardIsLive: boolean,
    trz_cardStatusCode: string,
    trz_cardPublicToken: number,
    cse_employe_id: number,
    cse_employe?: CseEmploye,
    trz_card_info: any,
    code_lettre_suivie: string;
    maskedPan?: string;
}

export interface TrzCardImage {
    cardId: string,
    file: string,
    id: string,
}

export const CseEmployeCardStatusCode = {
    ACTIVE: 1,
    ORDER_IN_PROGRESS: 3
};
