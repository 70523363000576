import {Inject, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BaseRepository, Docusign, DocusignDocument, SingleResult} from "@mcv/core";
import {HttpClient} from "@angular/common/http";
import {BASEURL} from "@mcv/config";

@Injectable({
    providedIn: 'root'
})
export class DocusignService extends BaseRepository<Docusign> {

    model = 'docusign';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }


    startSigning(public_id: string, scope: string, returnURL: string): Observable<SingleResult<Docusign>> {
        return this.httpClient.post<SingleResult<Docusign>>(`${this.baseUrl}/${this.model}/getSigningContractURL`, {
            public_id: public_id,
            scope: scope,
            returnURL: returnURL
        });
    }

    startSigningSEPA(public_id: string, returnURL: string, form: any): Observable<SingleResult<Docusign>> {
        return this.httpClient.post<SingleResult<Docusign>>(`${this.baseUrl}/${this.model}/getSigningContractURL`, {
            public_id: public_id,
            scope: 'sepa',
            returnURL: returnURL,
            ...form,
        });
    }

    getSignedContract(public_id: string, scope: string): Observable<SingleResult<DocusignDocument>> {
        return this.httpClient.post<SingleResult<DocusignDocument>>(`${this.baseUrl}/${this.model}/getSignedDocument`, {
            public_id: public_id,
            scope: scope
        });
    }

    checkSignatureValidated(public_id: string, scope: string, signatureId: string): Observable<SingleResult<Docusign>> {
        return this.httpClient.post<SingleResult<Docusign>>(`${this.baseUrl}/${this.model}/isSignatureCompleted`, {
            public_id: public_id,
            scope: scope,
            signatureId: signatureId
        });
    }
}
