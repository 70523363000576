import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, CseBank, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseBankService extends BaseRepository<CseBank> {
    model = 'cse-bank';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getRum(): Observable<SingleResult<string>> {
        return this.httpClient.get<SingleResult<string>>(`${this.baseUrl}/${this.model}/getRum`);
    }
}
