<div class="background">
    <ng-container *ngIf="signatureStatus === 'completed'; else signatureKO">
        <div class="align-content-center">
            <div class="text-center title p-5 text-primary">
                <h2>Félicitation !</h2>
            </div>
            <div class="text-center p-5">
                <h5 *ngIf="isSepaSignature">
                    Vous venez de signer votre mandat de prélèvement SEPA avec macartevacances
                </h5>
                <h5 *ngIf="isContractSignature">
                    Vous venez de signer votre contrat avec macartevacances
                </h5>
                <h5 *ngIf="isContractSignature === false && isSepaSignature === false">
                    Vous venez de signer votre document avec macartevacances
                </h5>
            </div>
        </div>
    </ng-container>

    <ng-template #signatureKO>
        <div class="text-center pt-5 text-danger">
            <h2>Attention !</h2>
        </div>
        <div class="text-center p-5">
            <h5 *ngIf="isSepaSignature">
                La signature de votre mandat de prélèvement SEPA à échoué, veuillez prendre contact avec le support
            </h5>
            <h5 *ngIf="isContractSignature">
                La signature de votre contrat à échoué, veuillez prendre contact avec le support
            </h5>
            <h5 *ngIf="isContractSignature === false && isSepaSignature === false">
                La signature de votre document à échoué, veuillez prendre contact avec le support
            </h5>
        </div>
    </ng-template>
</div>

