// tslint:disable-next-line:max-line-length
import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {SingleResult} from './Result';

export interface JsonApiBaseView<T> {
    type: string;
    id: number;
    attributes: T;
    relationships: any;
}

export interface JsonApiViewItem<T> {
    data: JsonApiBaseView<T>;
}

export interface MetaPaging {
    count?: number;
    current_page: number;
    has_next_page?: boolean;
    has_prev_page?: boolean;
    limit: number;
    page_count?: number;
}

interface JsonApiListInterface<T> {
    meta: {
        'record_count': number,
        'page_count': number,
        'page_limit': number
    };
    links: {
        'self': string,
        'first': string,
        'last': string,
        'next': string
    };
    data: Array<JsonApiBaseView<T>>;
}

interface ViewItem<T> {
    success: boolean;
    data: T | any;
}

export interface ListItem<T> {
    success: boolean;
    meta: MetaPaging;
    data: Array<T> | any;
}

interface SaveResult<T> {
    success: boolean;
    data: 'id' | any;
}

interface DeleteResult<T> {
    success: boolean;
    data: any;
}

export abstract class BaseRepository<T> {
    saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    autoRedirect = true;
    saving = false;
    loading = false;
    protected model: string;

    protected constructor(protected httpClient: HttpClient, @Inject(BASEURL) protected baseUrl: string) {
        this.saving$.subscribe(v => this.saving = v);
        this.loading$.subscribe(v => this.loading = v);
    }

    getModel(): string {
        return this.model;
    }

    getUrlForView(): string {
        return `${this.baseUrl}/${this.model}/view`;
    }

    getCount(params ?: HttpParams): Observable<SingleResult<number>> {
        const url = `${this.baseUrl}/${this.model}/count`;
        return this.httpClient.get<SingleResult<number>>(url, {params});
    }

    add(item: FormData | Partial<T>): Observable<SaveResult<T>> {
        if (this.isEditRequested(item)) {
            const id = (item instanceof FormData) ? item.get('public_id') : (item as any).public_id;
            return this.edit(id, item);
        }
        this.saving$.next(true);
        const url = `${this.baseUrl}/${this.model}/add`;
        return this.httpClient
            .post<SaveResult<T>>(url, item)
            .pipe(
                tap(_ => this.saving$.next(false)),
                switchMap(r => {
                    if (this.autoRedirect) {
                        return this.view(r.data.id);
                    }
                    return of({success: true, data: r.data});
                }),
                catchError(err => {
                    this.saving$.next(false);
                    return throwError(err);
                })
            );
    }

    getList(page: number = 1, limit: number = 9999, paramsToAdd?: HttpParams): Observable<ListItem<T>> {
        const url = `${this.baseUrl}/${this.model}/index`;
        let params: HttpParams = new HttpParams().set('limit', limit.toString())
            .set('page', page.toString());
        if (paramsToAdd) {
            params = this.appendExtraParams(params, paramsToAdd);
        }

        this.loading$.next(true);
        return this.httpClient.get<ListItem<T>>(`${url}`, {params})
            .pipe(
                tap(() => this.loading$.next(false)),
                catchError(() => {
                    this.loading$.next(false);
                    return of({data: [], success: false, meta: null});
                })
            );
    }

    getWithFinder(finder: string, page: number = 1, limit: number = 9999, paramsToAdd?: HttpParams): Observable<ListItem<any>> {
        let params: HttpParams = (!paramsToAdd) ? new HttpParams() : paramsToAdd;
        params = params.set(finder, '1');
        this.loading$.next(true);
        return this.getList(page, limit, params)
            .pipe(
                tap(() => this.loading$.next(false)),
            );
    }

    view(id: string): Observable<ViewItem<T>> {
        const url = `${this.baseUrl}/${this.model}/view/${id}`;
        this.loading$.next(true);
        return this.httpClient
            .get<ViewItem<T>>(url)
            .pipe(
                tap(_ => this.loading$.next(false)),
            );
    }

    search(key: string, value: string): Observable<ListItem<T>> {
        const url = `${this.baseUrl}/${this.model}/index`;
        const params: HttpParams = new HttpParams().set(key ? key : 'q', value);
        return this.httpClient.get<ListItem<T>>(`${url}`, {params});
    }

    lookup(key: string, value: string): Observable<ListItem<T>> {
        const url = `${this.baseUrl}/${this.model}/lookup`;
        const params: HttpParams = new HttpParams().set(key ? key : 'q', value);
        return this.httpClient.get<ListItem<T>>(`${url}`, {params});
    }

    edit(id: string, item: FormData | Partial<T>): Observable<ViewItem<T>> {
        this.saving$.next(true);
        const url = `${this.baseUrl}/${this.model}/edit/${id}`;
        return this.httpClient.post<ViewItem<T>>(url, item)
            .pipe(
                tap(_ => this.saving$.next(false)),
                switchMap(_ => {
                    if (this.autoRedirect) {
                        return this.view(id);
                    }
                    return of({success: true, data: null});
                }),
                catchError(err => {
                    this.saving$.next(false);
                    return throwError(err);
                })
            );
    }

    delete(id: string): Observable<DeleteResult<T>> {
        const url = `${this.baseUrl}/${this.model}/delete/${id}`;
        return this.httpClient.delete<DeleteResult<T>>(`${url}`);
    }

    protected appendExtraParams(params: HttpParams, extraParams: HttpParams): HttpParams {
        extraParams.keys()
            .forEach(key => {
                params = params.set(key, extraParams.get(key));
            });
        return params;
    }

    private isEditRequested(item: FormData | Partial<T>): boolean {
        if (item instanceof FormData) {
            if (!item.has('public_id')) {
                return false;
            }
            const public_id = item.get('public_id');
            return public_id !== 'null';
        }
        return !!(item as any).public_id;
    }

}
