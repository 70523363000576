<input (ngModelChange)="onChange($event)"
       (typeaheadLoading)="loading = $event"
       (typeaheadOnSelect)="select.emit($event)"
       [(ngModel)]="value"
       [mcvInputAutoFocus]="isFocus"
       [typeaheadAsync]="true"
       [typeaheadItemTemplate]="customItemTemplate"
       [typeaheadMinLength]="0"
       [typeaheadMultipleSearch]="true"
       [typeaheadOptionField]="'filter'"
       [typeahead]="suggestions$"
       aria-describedby="search-bar"
       class="form-control"
       placeholder="{{placeholder}}"
       type="search"
       typeaheadMultipleSearchDelimiters=" "
       typeaheadWaitMs="500"
       typeaheadWordDelimiters=","/>
