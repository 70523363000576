import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@cse/app/auth/guards/auth.guard';
import {SignatureResultComponent} from '@cse/app/cse/profile/pages/signature-result/signature-result.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/cse/tableau-de-bord',
        pathMatch: 'full'
    },
    {
        path: 'cse',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () => import('./cse/cse.module').then(m => m.CseModule)
    },
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    {
        path: 'inscription',
        loadChildren: () => import('./inscription/inscription.module').then(m => m.InscriptionModule)
    },
    {
        path: 'signature-result',
        component: SignatureResultComponent
    },
    {
        path: '**',
        redirectTo: '/cse/tableau-de-bord'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {enableTracing: false, relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
