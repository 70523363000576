import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'money'
})
export class MoneyPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe,
                private _sanitizer: DomSanitizer) {
    }

    transform(value: number | string, ...args: string[]) {
        if (value === null) {
            value = 0;
        }
        const money: string = (typeof value === "string" ? parseFloat(value) : value)?.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            .replace('.', ',');
        return (args[1] ? money.replace(',00', '') : money) + ' €';
    }
}
