export const environment = {
    production: true,
    client_env: 'prod',
    socketIo: 'https://ops.macartevacances.fr:8000',
    baseUrl: 'https://ops.macartevacances.fr/v1',
    promotionBaseUrl: 'https://api.promotion.macartevacances.com',
    CGU_MACARTEVACANCES: 'https://www.macartevacances.fr/conditions-generales',
    MENTIONS_MACARTEVACANCES: 'https://www.macartevacances.fr/mentions-legales',
    POLITIQUE_MACARTEVACANCES: 'https://www.macartevacances.fr/politique-de-confidentialite',
    CGU_TREEZOR: 'https://uploads-ssl.webflow.com/5dd310bf0e47ad35614be392/5f073a75aeed13aa59f12523_Treezor_%20Otium%20-%20CGU%20Carte.pdf',
    HIPAY_PUBLIC_LOGIN: '94680541.secure-gateway.hipay-tpp.com',
    HIPAY_PUBLIC_PASSWORD: 'Live_tzl8UPD0yqTyYFUh4glPhhNb',
    HIPAY_ENVIRONNEMENT: 'production',
    G_TAG: 'UA-158358329-3',
    G_TAG_CSE: 'UA-158358329-2',
    G_TAG_PRO: 'UA-158358329-4',
    G_MAP: 'AIzaSyBcODGvzgaUASadSDqg2vZcNJoTMktBvfE',
    RECAPTCHA_SITE_KEY: '6LfGKO0UAAAAAFLClc2q5cnqQoEWX9m5PzYBRJ_N',
    INTERCOM: 'zk6ui6g9',
    firebase: {
        apiKey: 'AIzaSyCm7AA9VxiqLW_U82vs5jTTasBXAFqf4BY',
        authDomain: 'app-ios-dfdec.firebaseapp.com',
        databaseURL: 'https://app-ios-dfdec.firebaseio.com',
        projectId: 'app-ios-dfdec',
        storageBucket: 'app-ios-dfdec.appspot.com',
        messagingSenderId: '76113917472',
        appId: '1:76113917472:web:dfcd2b60693189c301ee45',
        measurementId: 'G-9W8RWJYJ8Z',
        remoteConfig: {
            settings: {
                minimumFetchIntervalMillis: 1800000
            },
            defaults: {
                kycliveness_enabled: true,
                other_offer_tab_visible: false,
                production_kycliveness_enabled: true,
                production_offer_tab_visible: false,
                test_kycliveness_enabled: true,
                test_offer_tab_visible: false,
                refund_enabled: false,
                refund_production_enabled: false,
                refund_test_enabled: false
            }
        }
    },
    featureFlagsNames: {
        kycLiveness: 'production_kycliveness_enabled',
        refundEnabled: 'refund_production_enabled'
    },
    HEAP_APP_ID: {
        CSE: '2484032103',
        CSE_EMPLOYE: '1383399216'
    }
};
