import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TreeItem} from '@cse/app/cse/cardperimeter/interfaces/TreeItem';
import {BASEURL} from '@mcv/config';
import {BaseRepository, Perimetercategory, Result} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PerimetercategoryService extends BaseRepository<Perimetercategory> {
    model = 'perimetercategory';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    merge(formData: any): Observable<any> {
        const url = `${this.baseUrl}/${this.model}/merge`;
        return this.httpClient.post<any>(url, formData);
    }

    getThreaded(): Observable<Result<TreeItem>> {
        const url = `${this.baseUrl}/${this.model}/getThreaded`;
        return this.httpClient.get<Result<TreeItem>>(url);
    }
}
