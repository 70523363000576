import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CategorySelectComponent } from '@cse/app/shared/components/category-select/category-select.component';
import { ProfilePasswordComponent } from '@cse/app/shared/components/profile-password/profile-password.component';
import { SearchResultComponent } from '@cse/app/shared/components/search-result/search-result.component';
import { CategoryPipe } from '@cse/app/shared/pipes/category.pipe';
import { CseemployeSourcePipe } from '@cse/app/shared/pipes/cseemploye-source.pipe';
import { UiModule } from '@mcv/ui';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { VersionComponent } from './components/version/version.component';
import { InViewDirective } from './directives/in-view.directive';
import {
    BrowserCompatibilityWarningComponent
} from '@cse/app/shared/components/browser-compatibility-warning/browser-compatibility-warning.component';
import { AngularIbanModule } from 'angular-iban';
import { OrderFaqRedirectButtonComponent } from './components/order-faq-redirect-button/order-faq-redirect-button.component';

@NgModule({
    declarations: [
        NavbarComponent,
        SearchResultComponent,
        ProfilePasswordComponent,
        CategorySelectComponent,
        CategoryPipe,
        CseemployeSourcePipe,
        SafeUrlPipe,
        VersionComponent,
        InViewDirective,
        BrowserCompatibilityWarningComponent,
        OrderFaqRedirectButtonComponent
    ],
    exports: [
        NavbarComponent,
        CategorySelectComponent,
        SearchResultComponent,
        ProfilePasswordComponent,
        CategoryPipe,
        CseemployeSourcePipe,
        SafeUrlPipe,
        AngularIbanModule,
        VersionComponent,
        InViewDirective,
        BrowserCompatibilityWarningComponent,
        OrderFaqRedirectButtonComponent
    ],
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        AngularIbanModule,
        ReactiveFormsModule
    ]
})
export class SharedModule {
}
