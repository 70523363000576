import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'mcv-password-usage-rules',
    templateUrl: './password-usage-rules.component.html',
    styleUrls: ['./password-usage-rules.component.scss']
})
export class PasswordUsageRulesComponent implements OnInit, OnChanges {
    @Input() password: any
    @Input() passwordConfirmation: any

    maxLength = false
    number = false
    uppercase = false
    lowercase = false
    special = false
    same = false

    @Output() passOk = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.maxLength = this.password?.length >= 8
        this.number = !!this.password && this.password?.match(/\d+/g) != null
        const specialRegex = /(?=.*[!\/@#$€%^&*()\\[\]{}\-_+=~`|:;"'<>,.?])/
        this.special = !!this.password && specialRegex.test(this.password)
        const uppercaseRegex = /(?=.*[A-Z])/
        this.uppercase = !!this.password && uppercaseRegex.test(this.password)
        const lowerCaseRegex = /(?=.*[a-z])/
        this.lowercase = !!this.password && lowerCaseRegex.test(this.password)
        this.same = !!this.password && this.password === this.passwordConfirmation

        if (this.maxLength && this.number && this.uppercase && this.lowercase && this.special && this.same) {
            this.passOk.emit(true);
        } else {
            this.passOk.emit(false);
        }
    }
}
