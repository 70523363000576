import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mobilePhoneValidator } from '../../../ui/src/lib/validators/mobile-phone.validator';

const cseFields = {
    public_id: [],
    legalName: [null, [Validators.required]],
    legalRegistrationNumber: [null, [Validators.required]],
    same_invoice_address: [null]
};

const affiliateUserFields = {
    public_id: [],
    firstname: [null, [Validators.required]],
    lastname: [null, [Validators.required]],
    email: [null, [Validators.required]],
    mobile: [null, [Validators.required, mobilePhoneValidator()]],
    abo: []
};


const addressFields = {
    public_id: [],
    address1: [null, [Validators.required, Validators.maxLength(56)]],
    address2: [null, [Validators.maxLength(112)]],
    address3: [null, [Validators.maxLength(56)]],
    postcode: [null, [Validators.required]],
    city: [null, [Validators.required]],
    country: [null, [Validators.required]]
};

const personalFields = {
    public_id: [],
    title: [null, [Validators.required]],
    firstname: [null, [Validators.required]],
    lastname: [null, [Validators.required]],
    birthday: [null, [Validators.required]],
    birthCountry: [null, [Validators.required]],
    placeOfBirth: [null, [Validators.required]],
    nationality: [null, [Validators.required]]
};

const contactFields = {
    email: [null, [Validators.required, Validators.email]],
    mobile: [null, [Validators.required, mobilePhoneValidator()]]
};

const specificCseUserFields = {
    placeOfBirth: [null, [Validators.required]],
    specifiedUSPerson: [null, [Validators.required]],
    specifiedWWPerson: [null, [Validators.required]],
    abo: []
};

const specificCseEmployeFields = {
    email_alt: [null, [Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
    mobile: [null, [Validators.required, mobilePhoneValidator()]],
    specifiedUSPerson: [null, [Validators.required]],
    mobile_verified: [null]
};

const specificAffiliateFields = {
    legalName: [null, [Validators.required]],
    legalRegistrationNumber: [null, [Validators.required, Validators.pattern('[0-9]*')]]
};

export const CreateForms = (fb: FormBuilder): FormGroup => {
    const group = fb.group({
        ...personalFields,
        ...addressFields,
        ...specificCseEmployeFields
    });
    return truncField(group);
};

export const CreateConfirmForms = (fb: FormBuilder): FormGroup => {
    const group = fb.group({
        ...personalFields
    });
    return truncField(group);
};

export const CreateCseEmployePreboardingForm = (fb: FormBuilder): FormGroup => {
    return fb.group({
        ...personalFields,
        ...specificCseEmployeFields
    });
};

export const CreateCseUser = (fb: FormBuilder): FormGroup => {
    return truncField(fb.group({
        ...personalFields,
        ...specificCseUserFields,
        ...addressFields,
        ...contactFields
    }));
};

export const CreateAddressForm = (fb: FormBuilder): FormGroup => {
    return truncField(fb.group(addressFields));
};

export const CreateCseForm = (fb: FormBuilder): FormGroup => {
    return fb.group({ ...cseFields, ...addressFields });
};

export const CreateInvoiceAddress = (fb: FormBuilder, owner_type: string, type: string): FormGroup => {
    const invoiceType = {
        name: [null, Validators.required],
        email: [null, [Validators.email]],
        owner_public_id: [null],
        owner_type: [owner_type],
        type: [type]
    };
    return truncField(fb.group({ ...addressFields, ...invoiceType }));
};

export const CreateAffiliateUserForm = (fb: FormBuilder): FormGroup => {
    return fb.group({ ...affiliateUserFields });
};

export const CreateAffiliateForm = (fb: FormBuilder): FormGroup => {
    return truncField(fb.group({ ...specificAffiliateFields, ...addressFields }));
};

export const truncField = (group: FormGroup): FormGroup => {
    group.valueChanges.subscribe(form => {
        if (form.address1?.length > 56) {
            const newAddress1 = form.address1.slice(0, 56);
            group.patchValue({
                address1: newAddress1
            }, {
                emitEvent: false
            });
        }
        if (form.address2?.length > 112) {
            const newAddress2 = form.address2.slice(0, 112);
            group.patchValue({
                address2: newAddress2
            }, {
                emitEvent: false
            });
        }
    });
    return group;
};
