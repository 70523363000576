import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TrzBusinesses} from '@mcv/core';
import {CseService} from '@mcv/coreservices';
import {concat, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'mcv-business-search',
    templateUrl: './business-search.component.html',
    styleUrls: ['./business-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BusinessSearchComponent),
            multi: true
        }
    ],
})
export class BusinessSearchComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Output() selectBusiness: EventEmitter<TrzBusinesses> = new EventEmitter<TrzBusinesses>();
    @Input() buttonLabel = 'Choisir';
    @Input() textLabel = 'Veuillez nous indiquer le nom de votre entreprise référente (Dénomination sociale ou numéro de SIRET)';
    @Input() placeholder = 'Effectuer une recherche...'
    @Input() onFocus: Observable<boolean>
    disabled = false;
    business$: Observable<TrzBusinesses[]>;
    businessInput$: Subject<string> = new Subject<string>();
    selectedBusiness: TrzBusinesses;
    subscription: Subscription = new Subscription();
    loading = false;
    focus = false;
    mask = {
        placeholderChar: '_',
        lazy: false,
        mask: `000 000 000`
    };

    constructor(private cseService: CseService) {
    }

    onChange: any = (val: number) => {
    }

    onTouch: any = (val: number) => {
    }

    ngOnInit(): void {
        this.loadBusiness();
        this.initOnFocusListener();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    focusout(): void {
        this.focus = false;
    }

    validate() {
        this.onChange(this.selectedBusiness);
        this.selectBusiness.emit(this.selectedBusiness);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
    }

    private initOnFocusListener() {
        if (this.onFocus) {
            this.subscription.add(this.onFocus.subscribe(() => {
                this.focus = true;
            }))
        }
    }

    private loadBusiness() {
        this.business$ = concat(
            of([]), // default items
            this.businessInput$.pipe(
                filter(v => !!v),
                debounceTime(500),
                distinctUntilChanged(),
                tap(_ => this.loading = true),
                switchMap(term => this.cseService.recherche(term)),
                map(r => r.data),
                catchError(_ => of([])), // empty list on error
                tap(_ => this.loading = false),
            )
        );
    }

}
