import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Country} from '@mcv/core';

@Component({
    selector: 'mcv-country-select',
    templateUrl: './country-select.component.html',
    styleUrls: ['./country-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountrySelectComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class CountrySelectComponent implements OnInit, ControlValueAccessor {
    @Input() value: string = null;
    @Input() disabled: boolean;
    countries = Country;

    constructor() {
    }

    onChange: any = (val: number) => {
    }

    onTouch: any = (val: number) => {
    }

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    onSelectChange($event: { ccode: string, cname: string }): void {
        this.onChange($event ? $event.ccode : null);
    }
}
