import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { BaseRepository, CardStats, CseEmploye, CseEmployeStats, SingleResult } from '@mcv/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseEmployeService extends BaseRepository<CseEmploye> {
    protected model = 'cse-employe/cse-employe';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    logout(): Observable<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/app/logout`);
    }

    askNewmail(formData: any): Observable<any> {
        return this.httpClient.put<any>(`${this.baseUrl}/${this.model}/askNewMail`, formData);
    }

    checkPassword(password: string): Observable<SingleResult<any>> {
        return this.httpClient.post<any>(`${this.baseUrl}/cse-employe/app/checkPassword`, { password });
    }

    confirmCode(formData: any): Observable<CseEmploye> {
        return this.httpClient.put<CseEmploye>(`${this.baseUrl}/${this.model}/confirmCode`, formData);
    }

    askNewmobile(formData: any): Observable<any> {
        return this.httpClient.put<any>(`${this.baseUrl}/${this.model}/askNewMobile`, formData);
    }

    stats(id: string): Observable<SingleResult<CseEmployeStats>> {
        return this.httpClient.get<SingleResult<CseEmployeStats>>(`${this.baseUrl}/${this.model}/stats/${id}`);
    }

    cardStats(id: string): Observable<SingleResult<CardStats>> {
        return this.httpClient.get<SingleResult<CardStats>>(`${this.baseUrl}/${this.model}/cardStats/${id}`);
    }

    search(search: string, type: string, extraParams?: HttpParams): Observable<any> {
        let params: HttpParams = new HttpParams().set('q', search)
            .set('type', type);
        if (extraParams) {
            params = this.appendExtraParams(params, extraParams);
        }
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/search`, { params });
    }

    remove(publicId: string): Observable<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/remove/${publicId}`);
    }

    unremove(publicId: string): Observable<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/unremove/${publicId}`);
    }

    saveMultiple(formData: any): Observable<SingleResult<any>> {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/${this.model}/saveMultiple`, formData);
    }

    export(formData: any): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/${this.model}/export`, formData, {
            responseType: 'blob',
            observe: 'response'
        });
    }

    getStatsForCommande($commandeId, formData) {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/${this.model}/getStatsForCommande/${$commandeId}`, formData);
    }

    displayAnonymousEmail(email: string): Observable<SingleResult<any>> {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/${this.model}/displayAnonymousEmail`, { email });
    }

    askSendCode(email: string): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/cse-employe/activation/askSendCode`, { email });
    }

    lockAccount(publicId: string): Observable<SingleResult<CseEmploye>> {
        return this.httpClient.put<SingleResult<CseEmploye>>(`${this.baseUrl}/${this.model}/lockAccount/${publicId}`, {});
    }

    unlockAccount(publicId: string): Observable<SingleResult<CseEmploye>> {
        return this.httpClient.put<SingleResult<CseEmploye>>(`${this.baseUrl}/${this.model}/unlockAccount/${publicId}`, {});
    }

    updateTreezor(publicId: string): Observable<SingleResult<CseEmploye>> {
        return this.httpClient.post<SingleResult<CseEmploye>>(`${this.baseUrl}/${this.model}/updateTreezor/${publicId}`, {});
    }
}
