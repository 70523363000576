import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, Cse, Offer, OfferWebContainerVersion, SingleResult} from '@mcv/core';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class OfferService extends BaseRepository<Offer> {
    model = 'offer';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getLastWebContainer(): Observable<SingleResult<OfferWebContainerVersion>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/getLastWebContainer`);
    }

    updateWebContainer(formData: any): Observable<SingleResult<any>> {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/${this.model}/updateWebContainer`, formData);
    }

    applyOffer(csePublicId: string, offer: string) {
        return this.httpClient.put<SingleResult<Cse>>(`${this.baseUrl}/${this.model}/applyOffer/${csePublicId}`, {offer});
    }
}
