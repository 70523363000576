<div class='password--wrapper__position'>
    <input (blur)='onTouch()'
           (ngModelChange)='onChange($event)'
           [(ngModel)]='value'
           [autocomplete]='autocomplete'
           [disabled]='prefilled'
           [name]='name'
           [placeholder]='placeholder'
           class='form-control'
           type="{{displayPassword ? 'text':'password'}}">
    <div class='form-control-feedback'>
        <div (click)='toggleDisplay()'>
            <i class="fa fa-{{displayPassword ? 'eye':'eye-slash'}}"></i>
        </div>
    </div>
</div>
