import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import IMask from 'imask';
import * as moment from 'moment';
import {BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'mcv-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})
export class DatepickerComponent implements OnInit, ControlValueAccessor {
    @Input() placeholder = 'JJ/MM/AAAA';
    @Input() theme = 'theme-orange';
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() daysDisabled: number[];
    disabled: boolean;
    model: string | Date;
    bsConfig: Partial<BsDatepickerConfig>;
    nativeDisplay = false;
    dynamicmask: IMask.AnyMaskedOptions = {
        lazy: true,
        mask: `00/00/0000`,
    };

    constructor(private localeService: BsLocaleService,
                private deviceService: DeviceDetectorService) {
        this.nativeDisplay = this.deviceService.isMobile() || this.deviceService.isTablet();
        this.nativeDisplay = true;
        this.localeService.use('fr');
    }

    onChange: any = (val: number) => {
    }

    onTouch: any = (val: number) => {
    }

    ngOnInit(): void {
        this.bsConfig = {
            dateInputFormat: 'DD/MM/YYYY',
            useUtc: false,
            isAnimated: true,
            adaptivePosition: true,
            containerClass: this.theme
        };
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj) {
            const value = moment(obj);
            this.model = this.nativeDisplay ? value.format('DDMMYYYY') : value.toDate();
        }
    }

    changeModel(obj: Date | number) {
        let changeValue = null;
        if (obj) {
            if (this.nativeDisplay) {
                changeValue = moment(obj, 'DDMMYYYY')
                    .format('YYYY-MM-DD');
            } else {
                changeValue = moment(obj)
                    .format('YYYY-MM-DD');
            }
        }
        this.onChange(changeValue);
        this.onTouch();
    }
}
