import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: 'input[mcvInputAutoFocus]'
})
export class InputAutoFocusDirective implements OnChanges, AfterViewInit {
    // tslint:disable-next-line:no-input-rename
    @Input('mcvInputAutoFocus') isFocus = false
    // tslint:disable-next-line:no-input-rename
    @Input('selectText') selectText = false;

    constructor(private element: ElementRef<HTMLInputElement>) {
    }

    ngAfterViewInit(): void {
        if (this.isFocus) {
            window.setTimeout(() => this.element.nativeElement.focus());
        }
        if (this.selectText) {
            this.element.nativeElement.select();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isFocus) {
            this.element.nativeElement.focus({preventScroll: false});
        }
    }
}
