import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, CseDocument, KycDocumentType, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CseDocumentService extends BaseRepository<CseDocument> {
    model = 'cse-document';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    listTypes(): Observable<SingleResult<KycDocumentType>> {
        return this.httpClient.get<SingleResult<KycDocumentType>>(`${this.baseUrl}/${this.model}/listTypes`);
    }

    getPreviewData(documentId: number): Observable<Blob> {
        return this.httpClient.get<Blob>(`${this.baseUrl}/${this.model}/preview/${documentId}`, {
            responseType: 'blob' as 'json',
            observe: 'body'
        });
    }

    sendAllKycReview(cseId: number): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/sendAllKycReview/${cseId}`, {});
    }


}
