import {Injectable} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {FormatErrorPipe} from '../pipes/format-error.pipe';

@Injectable({
    providedIn: 'root'
})
export class McvnotifierService {

    constructor(private notifierService: NotifierService,
                private formatError: FormatErrorPipe) {
    }

    error(e: any): void {
        const message: string = this.formatError.transform(e);
        this.notifierService.notify('error', message);
    }

    success(message: string): void {
        this.notifierService.notify('success', message);
    }

    warning(message: string): void {
        this.notifierService.notify('warning', message);
    }

    notify(type: string, message: string): void {
        this.notifierService.notify(type, message);
    }
}
