import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CseService } from 'libs/coreservices/src/lib/services/cse.service';
import { CountryPipe } from './pipes/country.pipe';
import { DebugPipe } from './pipes/debug.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { ConnectionService } from './services/connection.service';
import { CseCommandeService } from './services/cse-commande.service';
import { CseUserService } from './services/cse-user.service';
import { WalletTypeService } from './services/wallet-type.service';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        CseService,
        CseUserService,
        CseCommandeService,
        ConnectionService,
        WalletTypeService,
        MoneyPipe,
        DebugPipe
    ],
    declarations: [
        DebugPipe,
        MoneyPipe,
        CountryPipe
    ],
    exports: [
        MoneyPipe,
        DebugPipe
    ]
})
export class CoreservicesModule {
}
