import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() url: string = null;
    @Input() base64: string = null;
    @Input() height: number = null;
    pdf$: Observable<Blob>;
    pdfbase64$: Observable<string>;

    constructor(private httpClient: HttpClient) {
    }

    ngOnInit(): void {
        if (this.url !== null) {
            this.pdf$ = this.httpClient
                .get(this.url, {
                    responseType: 'blob',
                })
                .pipe(
                    map((result: any) => {
                        return result;
                    })
                );
        } else {
            this.pdfbase64$ = of(this.base64)
        }
    }
}
