import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, WalletType} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class WalletTypeService extends BaseRepository<WalletType> {
    protected model = 'wallet-type';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getAllWalletType(): Observable<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/${this.model}/?visible_cse=1`);
    }

}
