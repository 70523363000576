export interface Address {
    id?: number;
    public_id: string;
    name: string;
    email: string;
    address1: string;
    address2: string;
    address3: string;
    postcode: string;
    city: string;
    country: string;
    owner_id: number;
    owner_type: 'cse';
    type: 'invoice';
    date_create: string;
    date_update: string;
}

export const ADDRESS_OWNER_TYPE_CSE = 'cse';

export const ADDRESS_TYPE_INVOICE = 'invoice';
export const ADDRESS_TYPE_DELIVERY = 'delivery';
