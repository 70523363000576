import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Injectable()
export abstract class ModalComponent {
    onClose: Subject<boolean> = new Subject<boolean>();

    protected constructor(protected modalRef: BsModalRef) {
    }

    closeModal(dirty = false) {
        this.onClose.next(dirty);
        this.modalRef.hide();
    }
}
