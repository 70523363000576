import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { Address, BaseRepository, SingleResult } from '@mcv/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AddressService extends BaseRepository<Address> {
    model = 'address';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    load(params: HttpParams): Observable<SingleResult<Address>> {
        return this.httpClient.get<SingleResult<Address>>(`${this.baseUrl}/${this.model}/load`, { params });
    }
}
