import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'mcv-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PasswordInputComponent),
            multi: true
        }
    ]
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor {
    @Input() autocomplete = 'off';
    @Input() name = 'password_hash';
    @Input() placeholder = 'Mot de passe';
    @Input() prefilled: boolean;
    disabled: boolean;
    value: string;
    displayPassword = false;

    constructor() {
    }

    onChange: any = (val: number) => {
    };

    onTouch: any = (val: number) => {
    };

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    toggleDisplay() {
        this.displayPassword = !this.displayPassword;
    }
}
