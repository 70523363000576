import {AfterViewInit, Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';

@Component({
    selector: 'mcv-yesno-select',
    templateUrl: './yesno-select.component.html',
    styleUrls: ['./yesno-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YesnoSelectComponent),
            multi: true
        }
    ]
})
export class YesnoSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Input() value: boolean;
    @Input() disabled: boolean;
    control: FormControl;
    items = [
        {'value': true, 'label': 'OUI'},
        {'value': false, 'label': 'NON'},
    ];

    constructor(public injector: Injector) {
    }

    onChange: any = (val: number) => {
    }

    onTouch: any = (val: number) => {
    }

    ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            setTimeout(() => {
                this.control = ngControl.control as FormControl;
            })
        }
    }

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    select(value: boolean) {
        this.onChange(value);
        this.change.emit(value);
    }
}
