import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, CseReportError, Result} from '@mcv/core';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CseImportErrorService extends BaseRepository<CseReportError> {
    model = 'cse-import-error-line';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getErrorTypes(formData: any): Observable<Result<string>> {
        return this.httpClient.post<Result<string>>(`${this.baseUrl}/${this.model}/getErrorTypes`, formData);
    }

}
