import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { BaseRepository, MetaPaging } from '@mcv/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

export class DatasourceWrapper<T> extends DataSource<T> {
    public data: T[] = [];
    public filteredData: T[] = [];
    public paging$: BehaviorSubject<MetaPaging> = new BehaviorSubject<MetaPaging>(null);
    public paging: MetaPaging;
    private data$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable()
        .pipe(debounceTime(300), distinctUntilChanged());
    private httpParams: HttpParams = new HttpParams();

    constructor(protected repository: BaseRepository<T>,
                protected formFilter?: FormGroup) {
        super();
        this.paging$.subscribe(p => this.paging = p);
    }

    static buildHttpParamsFilter(httpParams: HttpParams, values: Record<string, any>): HttpParams {
        // tslint:disable-next-line:forin
        for (const value in values) {
            const newVar = values[value];
            if (newVar !== undefined && newVar !== null) {
                httpParams = httpParams.set(value, newVar);
            }
        }
        return httpParams;
    }

    static buildSortHttpParams(httpParams: HttpParams, sortDirection?, sortField?): HttpParams {
        return httpParams.append('direction', sortDirection)
            .append('sort', sortField);
    }

    async loadData(page = 1, limit = 99999, sortDirection = 'asc', sortField?) {
        this.loadingSubject.next(true);
        let params: HttpParams = (!!sortDirection && !!sortField) ? DatasourceWrapper.buildSortHttpParams(this.httpParams, sortDirection, sortField) : this.httpParams;
        if (this.formFilter) {
            params = DatasourceWrapper.buildHttpParamsFilter(params, this.formFilter.value);
        }
        await this.repository
            .getList(page, limit, params)
            .pipe(
                tap(r => this.paging$.next(r.meta)),
                map(r => r.data),
                tap(_ => this.loadingSubject.next(false)),
                tap(d => this.data = [...d]),
                tap(d => this.filteredData = [...d]),
                catchError(err => {
                    return of([]);
                })
            )
            .toPromise();
        this.data$.next(this.data);
    }

    connect(collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
        return this.data$.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        // this.loadingSubject.complete();
        // this.data$.complete();
    }

    addCriteria(criteria: string, value: string): DatasourceWrapper<T> {
        this.httpParams = this.httpParams.append(criteria, value);
        return this;
    }
}
