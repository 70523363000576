import { Component, Inject, OnInit } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'mcv-signature-result',
    templateUrl: './signature-result.component.html',
    styleUrls: ['./signature-result.component.scss']
})
export class SignatureResultComponent implements OnInit {
    isSepaSignature = false;
    isContractSignature = false;

    signatureStatus: string;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.signatureStatus = this.route.snapshot.queryParams['status'] ?? this.route.snapshot.params['status'] ?? null;

        switch (this.route.snapshot.queryParamMap.get('document')) {
            case 'sepa':
                this.isSepaSignature = true;
                break;
            case 'cse':
                this.isContractSignature = true;
                break;
        }
    }
}
