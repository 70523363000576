<ng-container *ngIf="pdf$ | async as pdf">
    <ngx-extended-pdf-viewer (pageChange)="pageChange.emit($event)"
                             [src]="pdf"
                             [useBrowserLocale]="true"
                             height="600px"></ngx-extended-pdf-viewer>
</ng-container>
<ng-container *ngIf="pdfbase64$ | async as pdf">
    <ngx-extended-pdf-viewer (pageChange)="pageChange.emit($event)"
                             [base64Src]="pdf"
                             [useBrowserLocale]="true"
                             height="600px"></ngx-extended-pdf-viewer>
</ng-container>

