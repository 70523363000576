import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, CseCategory} from '@mcv/core';

@Injectable({
    providedIn: 'root'
})
export class CseCategoryService extends BaseRepository<CseCategory> {
    model = 'cse-category';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}
